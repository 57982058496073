<template>
<div>
    <!-- modal -->
    <b-modal id="invoiceCautionFormModal" centered size="lg" no-close-on-backdrop ref="invoiceCautionFormModal" :title=" $t('invoiceCaution.info')" ok-only :ok-title=" $t('others.delete')" ok-variant="warning" @close="clear()" @ok.prevent="deleted">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col class="col-lg-12" >
                        <label for="invoice-date-input">{{ $t('invoice.date') }}</label>
                        <b-form-group disabled>
                            <CustomDatePicker :datePickerValidateOption="0" v-model="recordDate" disabled></CustomDatePicker>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="col-lg-12 mt-1">
                        <label for="invoiceCaution-employee-input">{{ $t('invoiceCaution.employee') }}</label>
                        <b-form>
                            <validation-provider :name="$t('invoiceCaution.employee')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                                <b-form-select :state="errors.length > 0 ? false : null" size="sm" v-model="employeeSelect" value-field="id" text-field="name" :options="employeeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="invoiceCaution-note-input">{{ $t('invoiceCaution.note') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('invoiceCaution.note')" rules="required">
                                <b-form-textarea id="invoiceCaution-note-input" v-model="invoiceCautionNote" rows="5" maxlength="500" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getInvoiceList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            invoiceCautionNote: '',
            recordDate: null,
            searchControl: true,
            employeeId: 0,
            employeeSelect: 0,
            employeeOption: [],
        }
    },
    methods: {
        clear() {
            this.invoiceId = 0
            this.employeeId = 0
            this.note = ''
            this.recordDate= null
        },
        showInfo(id, invoiceId) {
            this.$refs['invoiceCautionFormModal'].show()
            this.id = id
            this.invoiceId = invoiceId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'InvoiceCaution'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employeeOption = response.data.employeeList
                    this.invoiceCautionNote = response.data.invoiceCaution.note
                    this.recordDate = response.data.invoiceCaution.recordDate
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    if (response.data.invoiceCaution.employeeId > 0) {
                        this.employeeSelect = response.data.invoiceCaution.employeeId
                    } else {
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                    }

                } else {
                    this.$bvModal.hide('invoiceCautionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        addOrUpdate() {
            const data = {
                id: this.id,
                note: this.invoiceCautionNote,
                employeeId: this.employeeSelect,
                invoiceId: this.invoiceId
            }
            const controller = {
                name: 'InvoiceCaution'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$SaveAlert()
                            this.$bvModal.hide('invoiceCautionFormModal')
                            this.clear()
                            this.getInvoiceList()
                        } else {
                            this.$CustomWarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        async deleted() {
            const data = {
                id: this.id
            }
            const controller = {
                name: 'InvoiceCaution'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.$bvModal.hide('invoiceCautionFormModal')
                            this.clear()
                            this.getInvoiceList()

                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getInvoiceList()
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
