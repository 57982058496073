var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"businessPartnerResponsibleFormModal",attrs:{"id":"businessPartnerResponsibleFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('businessPartnerResponsible.info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)},"close":function($event){return _vm.clear()}}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"col-md-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"businessPartnerResponsible-name-input"}},[_vm._v(_vm._s(_vm.$t('businessPartnerResponsible.name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('businessPartnerResponsible.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"businessPartnerResponsible-name-input","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"businessPartnerResponsible-surname-input"}},[_vm._v(_vm._s(_vm.$t('businessPartnerResponsible.surname')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('businessPartnerResponsible.surname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"businessPartnerResponsible-surname-input","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-md-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"businessPartnerResponsible-email-input"}},[_vm._v(_vm._s(_vm.$t('businessPartnerResponsible.email')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('businessPartnerResponsible.email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"businessPartnerResponsible-email-input","type":"email","maxlength":"50","state":errors.length > 0 ? false:null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"col-md-6 mt-1"},[_c('b-form',[_c('label',{attrs:{"for":"businessPartnerResponsible-telephone-input"}},[_vm._v(_vm._s(_vm.$t('businessPartnerResponsible.telephone')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('businessPartnerResponsible.telephone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"businessPartnerResponsible-telephone-input","type":"tel","maxlength":"20","state":errors.length > 0 ? false:null},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }