<template>
    <div>
        <accounting-books-receipt-form :subsidiaryLedgerId="subsidiaryLedgerId" ref="accountingBooksReceiptFormModal"></accounting-books-receipt-form>
        <div class="custom-search">
            <b-row>
                <b-col  class="col-md-2 mb-1">
                    <label for="start-date-input">{{ $t('accountingTransactionDoesntBalancedEntitiesReport.start_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="startDate"></CustomDatePicker>
                </b-col>
                <b-col  class="col-md-2 mb-1">
                    <label for="end-date-input">{{ $t('accountingTransactionDoesntBalancedEntitiesReport.end_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="endDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-8 custom-search d-flex justify-content-end">
                    <div class="d-flex align-items-center">
                        <b-button class="d-inline-block mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getAccountingTransactionDoesntBalancedEntitiesReportList()" @click="getAccountingTransactionDoesntBalancedEntitiesReportList()">{{ $t('accountingTransactionDoesntBalancedEntitiesReport.list') }}</b-button>
                    </div>
                </b-col>
            </b-row>
        </div>
        <!-- table -->
        <vue-good-table ref="accountingTransactionDoesntBalancedEntitiesReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
                      enabled: false,
                      externalQuery: searchTerm }" :select-options="{
                      enabled: false,
                      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                      selectionInfoClass: 'custom-class',
                      selectionText: 'rows selected',
                      clearSelectionText: 'clear',
                      disableSelectInfo: true, // disable the select info panel on top
                      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }" :pagination-options="{
                      enabled: true,
                      perPage:pageLength
                    }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field ==='accountingTransactionType'" class="text-nowrap">
                    {{ $t('accountingTransactionDoesntBalancedEntitiesReport.transaction_type') }}
                </span>
                <span v-else-if="props.column.field ==='number'" class="text-nowrap">
                    {{ $t('accountingTransactionDoesntBalancedEntitiesReport.number') }}
                </span>
                <span v-else-if="props.column.field ==='description'" class="text-nowrap">
                    {{ $t('accountingTransactionDoesntBalancedEntitiesReport.description') }}
                </span>
                <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                    {{ $t('accountingTransactionDoesntBalancedEntitiesReport.date') }}
                </span>
                <span v-else-if="props.column.field === 'difference'" class="text-nowrap">
                    {{ $t('accountingTransactionDoesntBalancedEntitiesReport.difference') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'address'">
                    <span>
                        {{ props.row.citySubDivision + ' / ' + props.row.city }}
                    </span>
                </span>
                <!-- Column: Process -->
                <span v-else-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.accountingTransactionId)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </span>
                </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getAccountingTransactionDoesntBalancedEntitiesReportList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                total: 0,
                pageLength: 10,
                searchTerm: '',
                dir: false,
                pageNo: 1,
                name: '',
                startDate: null,
                endDate: null,
                rows: [],
                columns: [
                    {
                        label: this.$t('accountingTransactionDoesntBalancedEntitiesReport.transaction_type'),
                        field: `accountingTransactionType`,
                    },
                    {
                        label: this.$t('accountingTransactionDoesntBalancedEntitiesReport.number'),
                        field: `number`,
                    },
                    {
                        label: this.$t('accountingTransactionDoesntBalancedEntitiesReport.currency'),
                        field: `currency`,
                    },
                    {
                        label: this.$t('accountingTransactionDoesntBalancedEntitiesReport.description'),
                        field: `description`,
                    },
                    {
                        label: this.$t('accountingTransactionDoesntBalancedEntitiesReport.date'),
                        field: `date`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('accountingTransactionDoesntBalancedEntitiesReport.difference'),
                        field: `difference`,
                    },
                    
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ],
                accountingId : 0,
            }
            
        },
        props: {
            subsidiaryLedgerId: {
                type: Number
            }
        },
        mounted() {
            this.startDate = new Date().toJSON()
            this.endDate = new Date().toJSON()
        },
        methods: {
            getAccountingTransactionDoesntBalancedEntitiesReportList() {
                this.rows = []
                const controller = {
                    name: 'AccountingTransaction',
                    actionName :'AccountingTransactionDoesntBalancedEntitiesReport'
                }
                const data = {
                    pageLength: this.pageLength,
                    pageNo: this.pageNo,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    subsidiaryLedgerId: this.subsidiaryLedgerId,
                }
                const payload = {
                    data: data,
                    controller: controller
                }

                let loader = this.$loading.show()
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        response.data.accountingDoesntBalancedEntitiesReportList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                    loader.hide()
                })
            },
            async getInfo(id) {
                this.$refs.accountingBooksReceiptFormModal.showInfo(id)
            },
        },
    }
    </script>
    
    <style lang="scss">
    .excel-css {
        float: right;
        margin-right: 1em;
        width: 9.5em;
        color: #7367f0;
        border: 1px solid #7367f0;
        background-color: transparent;
        margin-left: 1em;
        height: 2em;
        border-radius: 0.358rem;
    }
    
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    