<template>
    <div>
      <documents-form :getDocumentsList="getDocumentsList" ref="documentsFormModal"></documents-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:left;" class="custom-search d-flex justify-content-end">
                  <b-form-group>
                        <div class="d-flex align-items-center">
                          <b-form-input
                              size="sm"
                              v-model="searchTerm"
                              :placeholder=" $t('others.search_name')"
                              type="text"
                              class="d-inline-block"
                          />
                        </div>        
                  </b-form-group>
              </div>
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" size="sm" variant="outline-primary"
                  @click="getInfo(0)">{{ $t('documents.documents_add') }}</b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="documentsTable"
      ref="documentsTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" 
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'employee'" class="text-nowrap">
            {{ $t('documents.employee') }}
          </span>
          <span v-else-if="props.column.field === 'sequenceNo'" class="text-nowrap">
            {{ $t('documents.sequence_no') }}
          </span>
          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            {{ $t('documents.document_name') }}
          </span>
          <span v-else-if="props.column.field === 'documentType'" class="text-nowrap">
            {{ $t('documents.document_type') }}
          </span>
          <span v-else-if="props.column.field === 'documentTypeDetailName'" class="text-nowrap">
            {{ $t('documents.documents_content') }}
          </span>
          <span v-else-if="props.column.field === 'date'" class="text-nowrap">
            {{ $t('documents.date') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'sequenceNo'">
          <span>{{ props.row.sequenceNo }}</span>
        </span>
        <span v-else-if="props.column.field === 'name'">
            <span>
                {{props.row.name.length > 50 ? props.row.name.substring(0, 50) + '...' : props.row.name }}
            </span>
            <br>
            <span v-if="props.row.note !== null" style="font-size: smaller; color: dodgerblue;">
                {{ props.row.note }}
            </span>
        </span>
        <span v-else-if="props.column.field === 'type'">
          <span>
              {{props.row.type }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'documentType'">
          <span>
              {{props.row.documentType }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'documentTypeDetailName'">
          <span>
              {{props.row.documentTypeDetailName }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'date'">
          <span>
              {{ $formatFn(props.row.date) }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'employee'">
          <span>
              {{props.row.employee }}
          </span>
        </span>
          <!-- Column: Process -->
          <span v-else-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-05 ml-05" size="sm" @click="getInfo(props.row.id)">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button v-b-tooltip.html :title="$t('others.download')" v-b-modal.modal-top variant="success"
                class="btn-icon mb-05 ml-05" size="sm" @click="download(props.row)">
                <feather-icon icon="DownloadIcon" />
              </b-button>
              <b-button v-if="moduleId == 4" v-b-tooltip.html :title="$t('others.create_certificate')" v-b-modal.modal-top variant="success"
                class="btn-icon mb-05 ml-05" size="sm" @click="createCertificate(props.row)">
                <feather-icon icon="DownloadCloudIcon" />
              </b-button>
              <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-05 ml-05"
                size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
    //eslint-disable
  export default {
    props: {
      recordId: Number,
      moduleId: Number,
      isCyprus: Boolean
    },
    data() {
      return {
        dir: false,
        columns: [
          
          {
            label: this.$t('documents.sequence_no'),
            field: `sequenceNo`,
          },
          {
            label: this.$t('documents.document_name'),
            field: `name`,
          },
          {
            label: this.$t('documents.type'),
            field: `type`,
          },
          {
            label: this.$t('documents.document_type'),
            field: `documentType`,
          },
          {
            label: this.$t('documents.documents_content'),
            field: `documentTypeDetailName`,
          },
          {
            label: this.$t('documents.date'),
            field: `date`,
            formatFn: this.$formatFn,
          },
          {
            label: this.$t('documents.employee'),
            field: `employee`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '15em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted() {
      this.getDocumentsList()
    },
    methods: {
      async deleted(data) {
        data = { id: data.id }
        const controller = { name: 'Document' }
        const payload = { data: data, controller: controller }
        this.$DeleteConfirm().then(result => {
          if (result.value) {
            this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
              if (response.data.resultStatus == true) {
                this.$DeleteAlert()
                this.getDocumentsList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getDocumentsList()
              }
            })
          }
        })
      },
      async download(data) {
        data = { id: data.id }
        const controller = { name: 'Document' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/download', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      },
      async createCertificate(data) {
        data = { documentId: data.id, patentId: this.recordId }
        const controller = { name: 'Patent', actionName: 'PreparePatentCertificate' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$CreateCertificateAlert()
            this.getDocumentsList()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      },
      async getInfo(id) {
        this.$refs.documentsFormModal.showInfo(id, this.recordId, this.moduleId, this.isCyprus)  
      },
      getDocumentsList() {       
        this.rows = []
        const data = {recordId: this.recordId, moduleId: this.moduleId}
        const controller = { name: 'Document', actionName: 'GetDocumentListByModuleIdAndRecordId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        if (response.data.resultStatus == true) {
                this.rows = response.data.documentList
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  