<template>
<div>
    <reminder-form ref="reminderFormModal"></reminder-form>
    <agent-note-form ref="agentNoteFormModal"></agent-note-form>
    <client-form ref="clientFormModal"></client-form>
    <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
    <!-- modal -->
    <b-modal id="domainFormModal" centered size="xl" no-close-on-backdrop ref="domainFormModal" :title=" $t('domain.domain_info')" hide-footer>
        <b-card>
            <div class="custom-search d-flex justify-content-end">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                            {{$t('others.ok_title')}}
                        </b-button>
                        <b-dropdown v-if="id > 0" style="height: 2.1em;" class="ml-05" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                            <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="downloadDomainWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                    <span>{{wordTemplate.name}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="SendIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="prepareDomainMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                    <span>{{mailTemplate.explanation}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-md-4">
                        <b-form>
                            <label for="domain-ref-code-input">{{ $t('domain.domain_ref_code') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="domain-ref-code-input" v-model="domainRefCode" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4">
                        <b-form>
                            <label for="domain-name-input">{{ $t('domain.domain_name') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name=" $t('domain.domain_name')" rules="required">
                                    <b-form-input size="sm" id="domain-name-input" v-model="domainName" maxlength="50" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4">
                        <b-form>
                            <label for="domain-status-input">{{ $t('domain.status') }}</label>
                            <b-form-select size="sm" v-model="domainStatusSelect" value-field="id" text-field="name" :options="domainStatusOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-4">
                        <label for="domain-register-date-input">{{ $t('domain.allocation_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="registerDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col-md-4">
                        <label>{{ $t('domain.renewal_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="renewalDate"></CustomDatePicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-12">
                        <label for="textarea-default">{{ $t('domain.notes') }}</label>
                        <b-form-textarea id="textarea-default" :placeholder=" $t('domain.notes')" v-model="notes" rows="1" />
                    </b-col>
                </b-row>
                <b-row v-if="hiddenControl == false">
                    <b-col class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="domain-agent-input"> {{ $t('domain.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('domain.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="domain-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="domain-agent-input" v-model="domainAgentSelect" :options="domainAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-dropdown style="width: 4em; height: 2.5em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_client')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(domainAgentSelect.clientAccountingId)">
                                            <span>{{this.$t('others.show_note')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(domainAgentSelect.clientId)">
                                            <span>{{this.$t('others.show_agent')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-else-if="hiddenControl == true">
                    <b-col class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="domain-agent-input"> {{ $t('domain.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('domain.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="domain-agent-input" :state="errors.length > 0 ? false:null">
                                        <v-select class="agentInput" id="domain-agent-input" v-model="domainAgentSelect" :options="domainAgentOption" @input="setClientId" label="clientName" @search="onSearch">
                                        </v-select>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-button variant="success" style="width: 4em; height: 3em; margin-bottom: 1em" size="sm" v-b-tooltip.html :title="$t('others.add_client')" @click="getClientInfo(0)">
                                        <feather-icon icon="PlusCircleIcon" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <label for="domain-agent-ref-code-input">{{ $t('domain.domain_agent_ref_code') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="domain-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab :title=" $t('domain.owner')" active style="width:100%;">
                            <b-card-text>
                                <domain-owner-list :getClientInfo="getClientInfo" :getDomainList="getDomainList" :domainId="this.id"></domain-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('domain.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="7"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('domain.responsible')" style="width:100%;">
                            <b-card-text>
                                <domain-responsible-list :domainId="this.id" :clientId="this.clientId"></domain-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="7" :recordNo="this.domainRefCode"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="7"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDomainList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            domainName: '',
            domainRefCode: '',
            registerDate: null,
            renewalDate: null,
            domainStatusSelect: 0,
            domainStatusOption: [],
            domainAgentSelect: 0,
            domainAgentSelect1: 0,
            domainAgentOption: [],
            agentReferenceNo: '',
            notes: '',
            hiddenControl: true,
            wordTemplateList: [],
            mailTemplateList: [],
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            dateOption: {
                date: true,
                delimiter: '.',
                datePattern: ['d', 'm', 'Y']
            },
            clientAccountingId: 0,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 7, this.id)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 7)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            const trimmedSearch = search.trim()
            if (trimmedSearch.length) {
                loading(true);
                this.search(loading, trimmedSearch, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    ClientAccountingTypeId: 1,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.domainAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        async showInfo(id) {
            this.$refs['domainFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false

            const data = {
                id: this.id
            }
            const controller = {
                name: 'Domain'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    this.domainRefCode = response.data.domain.recordNo
                    this.domainName = response.data.domain.name
                    this.domainStatusOption = response.data.domainStatusList
                    this.domainStatusSelect = response.data.domain.domainStatusId
                    this.registerDate = response.data.domain.registerDate
                    this.renewalDate = response.data.domain.renewalDate
                    this.notes = response.data.domain.note
                    this.agentReferenceNo = response.data.domain.agentReferenceNo
                    this.domainStatusOption.splice(0, 0, this.$nullSelected())
                    if (response.data.domain.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.domain.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.domainAgentOption = clientAccountingResponse.data.clientList
                            this.domainAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })

                    } else {
                        this.domainAgentOption = []
                        this.domainAgentSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.domain.clientAccountingId > 0)
                            accountingId = response.data.domain.clientAccountingId
                        if (response.data.domain.invoiceClientAccountingId > 0)
                            accountingId = response.data.domain.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 7,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('domainFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        downloadDomainWordFile(documentId, name) {
            const data = {
                moduleId: 7,
                documentId: documentId,
                domainId: this.id
            }
            const controller = {
                name: 'Domain',
                actionName: 'DownloadDomainWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },

        addOrUpdate() {
            if (this.renewalDate == "") {
                this.renewalDate = null
            }
            const data = {
                id: this.id,
                name: this.domainName,
                agentReferenceNo: this.agentReferenceNo,
                clientAccountingId: this.domainAgentSelect.clientAccountingId,
                domainStatusId: this.domainStatusSelect,
                registerDate: this.registerDate,
                renewalDate: this.renewalDate,
                note: this.notes
            }
            const controller = {
                name: 'Domain'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getDomainList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.domainId
                                this.domainRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareDomainMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                domainId: this.id
            }
            const controller = {
                name: 'Domain',
                actionName: 'PrepareDomainMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

.justify-content-end-manuel {
    justify-content: flex-start !important;
}

.d-flex-manuel {
    display: flex !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

[dir] .mt-2-manuel,
[dir] .my-2-manuel {
    margin-top: 1.5em !important;
    margin-bottom: 4em !important;
}

.iconButton {
    height: 2.8em;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.no-calendar::-webkit-calendar-picker-indicator {
    display: none;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
