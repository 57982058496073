<template>
<div>
    <patent-xml-upload-form ref="patentXmlUploadFormModal"></patent-xml-upload-form>
    <patent-wipo-xml-upload-form ref="patentWipoXmlUploadFormModal"></patent-wipo-xml-upload-form>
    <design-form ref="designFormModal"></design-form>
    <patent-form ref="patentFormModal"></patent-form>
    <b-modal id="findRecordFormModal" centered size="lg" no-close-on-backdrop ref="findRecordFormModal" :title="$t('findRecord.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" @ok.prevent="emitRecordId" cancel-variant="outline-secondary" @close="clear">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-12 col-md-12">
                    <label for="find-record-folder-no-input"> {{ $t('findRecord.folder_no') }} </label>
                    <b-input-group>
                        <validation-provider :name=" $t('findRecord.folder_no')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-group label-for="find-record-folder-no-input" :state="errors.length > 0 ? false:null" style="width: 100%;">
                                <v-select class="agentInputCSS" style="width: 100%;" id="find-record-folder-no-input" v-model="recordNoSelect" :options="recordNoOption" label="record" @search="onSearch" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <b-input-group-append>
                            <b-dropdown style="width: 4em; height: 2em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                <template #button-content>
                                    <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                </template>
                                <b-dropdown-item v-if="moduleId == 4" @click="getWipoXml(0)">
                                    <span>{{ $t('findRecord.add_from_wipo_xml') }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="moduleId == 4" @click="getEpoXml(0)">
                                    <span>{{ $t('findRecord.add_from_epo_xml') }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="moduleId == 4" @click="addPatent(0)">
                                    <span>{{ $t('findRecord.patent_new_add') }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="moduleId == 5" @click="addDesign(0)">
                                    <span>{{ $t('findRecord.design_new_add') }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    data() {
        return {
            id: 0,
            recordId: 0,
            recordNoSelect: 0,
            recordNoOption: [],
            recordNoId: 0,
            importedRecordId: 0,
            moduleId: 0
        }
    },
    methods: {
        showInfo(moduleId) {
            this.moduleId = moduleId
            this.$refs['findRecordFormModal'].show()
        },
        async getEpoXml() {
            this.$refs.patentXmlUploadFormModal.showInfo(0)
        },
        async getWipoXml() {
            this.$refs.patentWipoXmlUploadFormModal.showInfo(0)
        },
        async addPatent(id) {
            this.$refs.patentFormModal.showInfo(id)
        },
        async addDesign(id) {
            this.$refs.designFormModal.showInfo(id)
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    moduleId: this.moduleId,
                    recordNo: search
                }
                const controller = {
                    name: 'Module',
                    actionName: 'GetRecordListbyModuleIdAndRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.recordNoOption = response.data.recordList
                    loading(false)
                    this.$emit('exportValue', this.recordNoSelect);
                })
            }
        },
        async emitRecordId() {
            this.$emit('importedRecordId', this.recordNoSelect.recordId);
            console.log(this.recordNoSelect.recordId)
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.$bvModal.hide('findRecordFormModal')
            
        },
        clear() {
            this.recordNoSelect = 0,
                this.recordNoOption = [],
                this.patentCheck = true,
                this.designCheck = true
        }
    }
}
</script>
<style>
    .agentInputCSS {
        width: 100% !important;
        max-width: none;
        min-width: 700px;
        box-sizing: border-box; 
    }
    .b-input-group {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
    }
    .b-input-group-append {
        flex-shrink: 0;
    }
    .v-select .dropdown-menu {
        max-height: 200px;
        overflow-y: auto;
    }
    .modal-dialog {
        max-width: 80%;
        width: 100%;
      }
</style>