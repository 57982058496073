import i18n from "@/libs/i18n";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/Home",
      name: "Home",
      component: () => import("@/views/Home.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Brand",
      name: "Brand",
      component: () => import("@/views/pages/Brand.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Patent",
      name: "Patent",
      component: () => import("@/views/pages/Patent/Patent.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Trademark",
      name: "Trademark",
      component: () => import("@/views/pages/Trademark/Trademark.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Design",
      name: "Design",
      component: () => import("@/views/pages/Design/Design.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DesignDeadlineReport",
      name: "DesignDeadlineReport",
      component: () => import("@/views/pages/Reports/DesignDeadlineReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DesignBulletinCheckReport",
      name: "DesignBulletinCheckReport",
      component: () =>
        import("@/views/pages/BulletinReport/DesignBulletinCheckReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DesignOpposition",
      name: "DesignOpposition",
      component: () => import("@/views/pages/Design/DesignOpposition.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Translator",
      name: "Translator",
      component: () => import("@/views/pages/Translator/Translator.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DesignProduct",
      name: "DesignProduct",
      component: () => import("@/views/pages/Design/DesignProduct.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Accounting",
      name: "Accounting",
      component: () => import("@/views/pages/Accounting.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Country",
      name: "Country",
      component: () => import("@/views/pages/Country.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Employee",
      name: "Employee",
      component: () => import("@/views/pages/HumanResources/Employee.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/EmploymentContractType",
      name: "EmploymentContractType",
      component: () =>
        import("@/views/pages/Definitions/EmploymentContractType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/EmploymentContract",
      name: "EmploymentContract",
      component: () =>
        import("@/views/pages/HumanResources/EmploymentContract.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/EmployeeHourlyAnnualLeave",
      name: "EmployeeHourlyAnnualLeave",
      component: () =>
        import("@/views/pages/HumanResources/EmployeeHourlyAnnualLeave.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/EmployeeAnnualLeave",
      name: "EmployeeAnnualLeave",
      component: () =>
        import("@/views/pages/HumanResources/EmployeeAnnualLeave.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/HumanResources",
      name: "HumanResources",
      component: () => import("@/views/pages/HumanResources.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Departments",
      name: "Departments",
      component: () => import("@/views/pages/Definitions/Departments.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/MailAccount",
      name: "MailAccount",
      component: () => import("@/views/pages/Definitions/MailAccount.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/MailTemplate",
      name: "MailTemplate",
      component: () => import("@/views/pages/Definitions/MailTemplate.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/EmployeeClassification",
      name: "EmployeeClassification",
      component: () =>
        import("@/views/pages/HumanResources/EmployeeClassification.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/MyAccount",
      name: "MyAccount",
      component: () => import("@/views/pages/MyAccount/MyAccount.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/HourlyAnnualLeaveByEmployee",
      name: "HourlyAnnualLeave",
      component: () =>
        import("@/views/pages/MyAccount/HourlyAnnualLeaveByEmployee.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AnnualLeaveByEmployee",
      name: "AnnualLeave",
      component: () =>
        import("@/views/pages/MyAccount/AnnualLeaveByEmployee.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/HourlyAnnualLeaveManagerApprove",
      name: "HourlyAnnualLeaveManagerApprove",
      component: () =>
        import("@/views/pages/Managers/HourlyAnnualLeaveApprove.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/WordTemplates",
      name: "WordTemplates",
      component: () => import("@/views/pages/Definitions/WordTemplates.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Client",
      name: "Client",
      component: () => import("@/views/pages/Client/Client.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Domain",
      name: "Domain",
      component: () => import("@/views/pages/Domain/Domain.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DomainOpposition",
      name: "DomainOpposition",
      component: () => import("@/views/pages/Domain/DomainOpposition.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ContactTypes",
      name: "ContactTypes",
      component: () => import("@/views/pages/Definitions/ContactTypes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/MailContactTypes",
      name: "MailContactTypes",
      component: () => import("@/views/pages/Definitions/MailContactTypes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PriceLists",
      name: "PriceLists",
      component: () => import("@/views/pages/Definitions/PriceLists.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DocumentTypes",
      name: "DocumentTypes",
      component: () => import("@/views/pages/Definitions/DocumentTypes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceSendTypes",
      name: "InvoiceSendTypes",
      component: () => import("@/views/pages/Definitions/InvoiceSendTypes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ResponsibleFields",
      name: "ResponsibleFields",
      component: () =>
        import("@/views/pages/Definitions/ResponsibleFields.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Positions",
      name: "Positions",
      component: () => import("@/views/pages/Definitions/Positions.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Titles",
      name: "Titles",
      component: () => import("@/views/pages/Definitions/Titles.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Languages",
      name: "Languages",
      component: () => import("@/views/pages/Definitions/Languages.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AnnualLeaveType",
      name: "AnnualLeaveType",
      component: () =>
        import("@/views/pages/HumanResources/AnnualLeaveType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LiableFields",
      name: "LiableFields",
      component: () => import("@/views/pages/Definitions/LiableFields.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Lexology",
      name: "Lexology",
      component: () => import("@/views/pages/ExternalSources/Lexology.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Holidays",
      name: "Holidays",
      component: () => import("@/views/pages/Definitions/Holidays.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentStatus",
      name: "PatentStatus",
      component: () =>
        import("@/views/pages/Definitions/Status/PatentStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DesignStatus",
      name: "DesignStatus",
      component: () =>
        import("@/views/pages/Definitions/Status/DesignStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/JobStatus",
      name: "JobStatus",
      component: () => import("@/views/pages/Definitions/Status/JobStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/JobStatusRelation",
      name: "JobStatusRelation",
      component: () =>
        import("@/views/pages/Definitions/JobStatusRelation.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/JobReminderCriterion",
      name: "JobReminderCriterion",
      component: () =>
        import("@/views/pages/Definitions/JobReminderCriterion.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Sectors",
      name: "Sectors",
      component: () => import("@/views/pages/Definitions/Sectors.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PermissionGroup",
      name: "PermissionGroup",
      component: () => import("@/views/pages/Developers/PermissionGroup.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PermissionTemplate",
      name: "PermissionTemplate",
      component: () =>
        import("@/views/pages/Developers/PermissionTemplate.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Permission",
      name: "Permission",
      component: () => import("@/views/pages/Developers/Permission.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AnnualLeaveRight",
      name: "AnnualLeaveRight",
      component: () => import("@/views/pages/Developers/AnnualLeaveRight.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AnnualLeaveStatus",
      name: "AnnualLeaveStatus",
      component: () => import("@/views/pages/Developers/AnnualLeaveStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ClientResponsibleReport",
      name: "ClientResponsibleReport",
      component: () =>
        import("@/views/pages/Reports/ClientResponsibleReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentUpdateFields",
      name: "PatentUpdateFields",
      component: () => import("@/views/pages/Reports/PatentUpdateFields.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TranslateReport",
      name: "TranslateReport",
      component: () => import("@/views/pages/Translator/TranslateReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/BulletinUpload",
      name: "BulletinUpload",
      component: () => import("@/views/pages/Tpmk/BulletinUpload.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/BaseTimer",
      name: "BaseTimer",
      component: () => import("@/layouts/components/BaseTimer.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentTransactionReport",
      name: "PatentTransactionReport",
      component: () =>
        import(
          "@/views/pages/Patent/PatentReports/PatentTransactionReport.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentBulletinAnalyseReport",
      name: "PatentBulletinAnalyseReport",
      component: () =>
        import("@/views/pages/Bulletin/PatentBulletinAnalyseReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DesignBulletinAnalyseReport",
      name: "DesignBulletinAnalyseReport",
      component: () =>
        import("@/views/pages/Bulletin/DesignBulletinAnalyseReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkBulletinAnalyseReport",
      name: "TrademarkBulletinAnalyseReport",
      component: () =>
        import("@/views/pages/Bulletin/TrademarkBulletinAnalyseReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ServiceTypes",
      name: "ServiceTypes",
      component: () => import("@/views/pages/Definitions/ServiceTypes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Services",
      name: "Services",
      component: () => import("@/views/pages/Definitions/Services.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AgentAnnuityPrice",
      name: "AgentAnnuityPrice",
      component: () => import("@/views/pages/AgentAnnuityPrice.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Annuity",
      name: "Annuity",
      component: () => import("@/views/pages/Annuity.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ServiceFees",
      name: "ServiceFees",
      component: () => import("@/views/pages/Definitions/ServiceFees.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AnnuityDetailStatus",
      name: "AnnuityDetailStatus",
      component: () =>
        import("@/views/pages/Definitions/AnnuityDetailStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/FTPClientTest",
      name: "FTPClientTest",
      component: () => import("@/views/pages/FTPClientTest.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NoteType",
      name: "NoteType",
      component: () => import("@/views/pages/Definitions/NoteType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentNoteType",
      name: "PatentNoteType",
      component: () => import("@/views/pages/Definitions/PatentNoteType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentAnnuityStatus",
      name: "PatentAnnuityStatus",
      component: () =>
        import("@/views/pages/Definitions/Status/PatentAnnuityStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentAnnuityWillPay",
      name: "PatentAnnuityWillPay",
      component: () =>
        import("@/views/pages/Definitions/PatentAnnuityWillPay.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkApplicationType",
      name: "TrademarkApplicationType",
      component: () =>
        import("@/views/pages/Definitions/TrademarkApplicationType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NationalTrademarkApplicationType",
      name: "NationalTrademarkApplicationType",
      component: () =>
        import(
          "@/views/pages/Definitions/NationalTrademarkApplicationType.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkStatus",
      name: "TrademarkStatus",
      component: () =>
        import("@/views/pages/Definitions/Status/TrademarkStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NiceClassType",
      name: "NiceClassType",
      component: () => import("@/views/pages/Definitions/NiceClassType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentBulletinCheck",
      name: "PatentBulletinCheck",
      component: () =>
        import("@/views/pages/BulletinReport/PatentBulletinCheck.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkSearchType",
      name: "TrademarkSearchType",
      component: () =>
        import("@/views/pages/Definitions/TrademarkSearchType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkSearchStatus",
      name: "TrademarkSearchStatus",
      component: () =>
        import("@/views/pages/Definitions/TrademarkSearchStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkNoteType",
      name: "TrademarkNoteType",
      component: () =>
        import("@/views/pages/Definitions/TrademarkNoteType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/MailToRead",
      name: "MailToRead",
      component: () => import("@/views/pages/Definitions/MailToRead.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkSearchDatabase",
      name: "TrademarkSearchDatabase",
      component: () =>
        import("@/views/pages/Definitions/TrademarkSearchDatabase.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkSearchSuccessRate",
      name: "TrademarkSearchSuccessRate",
      component: () =>
        import("@/views/pages/Definitions/TrademarkSearchSuccessRate.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkType",
      name: "TrademarkType",
      component: () => import("@/views/pages/Definitions/TrademarkType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentCertificate",
      name: "PatentCertificate",
      component: () =>
        import("@/views/pages/Patent/PatentReports/PatentCertificate.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkSearch",
      name: "TrademarkSearch",
      component: () => import("@/views/pages/Trademark/TrademarkSearch.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NationalTrademarkSearch",
      name: "NationalTrademarkSearch",
      component: () =>
        import("@/views/pages/Trademark/NationalTrademarkSearch.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentWorkingReminder",
      name: "PatentWorkingReminder",
      component: () =>
        import("@/views/pages/Patent/PatentReports/PatentWorkingReminder.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentAnnualFeeReminder",
      name: "PatentAnnualFeeReminder",
      component: () =>
        import(
          "@/views/pages/Patent/PatentReports/PatentAnnualFeeReminder.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkOpposition",
      name: "TrademarkOpposition",
      component: () =>
        import("@/views/pages/Trademark/TrademarkOpposition.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NationalTrademark",
      name: "NationalTrademark",
      component: () => import("@/views/pages/Trademark/NationalTrademark.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NationalPatent",
      name: "NationalPatent",
      component: () => import("@/views/pages/Patent/NationalPatent.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/PatentSearchDatabase",
      name: "PatentSearchDatabase",
      component: () =>
        import("@/views/pages/Definitions/PatentSearchDatabase.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TimeType",
      name: "TimeType",
      component: () => import("@/views/pages/Developers/TimeType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/HourlyAnnualLeaveApprove",
      name: "HourlyAnnualLeaveApprove",
      component: () =>
        import("@/views/pages/Managers/HourlyAnnualLeaveApprove.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/HourlyAnnualLeaveReport",
      name: "HourlyAnnualLeaveReport",
      component: () =>
        import("@/views/pages/HumanResources/HourlyAnnualLeaveReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ClientAccountingDifferentInvoiceReport",
      name: "ClientAccountingDifferentInvoiceReport",
      component: () =>
        import(
          "@/views/pages/Reports/ClientAccountingDifferentInvoiceReport.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ClientAccountingInvoiceAlertDayReport",
      name: "ClientAccountingInvoiceAlertDayReport",
      component: () =>
        import(
          "@/views/pages/Reports/ClientAccountingInvoiceAlertDayReport.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ClientAccountingSpecialInvoiceReport",
      name: "ClientAccountingSpecialInvoiceReport",
      component: () =>
        import(
          "@/views/pages/Reports/ClientAccountingSpecialInvoiceReport.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NoteToBeOnInvoice",
      name: "NoteToBeOnInvoice",
      component: () =>
        import("@/views/pages/Definitions/NoteToBeOnInvoice.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Invoice",
      name: "Invoice",
      component: () => import("@/views/pages/Finance/Invoice/Invoice.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AdminEmployeeAnnualLeave",
      name: "AdminEmployeeAnnualLeave",
      component: () =>
        import("@/views/pages/Admin/AdminEmployeeAnnualLeave.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AdminEmployeeHourlyAnnualLeave",
      name: "AdminEmployeeHourlyAnnualLeave",
      component: () =>
        import("@/views/pages/Admin/AdminEmployeeHourlyAnnualLeave.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TranslatorPayments",
      name: "TranslatorPayments",
      component: () =>
        import("@/views/pages/Finance/Accounting/TranslatorPayments.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceHeader",
      name: "InvoiceHeader",
      component: () =>
        import("@/views/pages/Finance/Definitions/InvoiceHeader.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Bank",
      name: "Bank",
      component: () => import("@/views/pages/Finance/Definitions/Bank.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Ledes",
      name: "Ledes",
      component: () => import("@/views/pages/Finance/Definitions/Ledes.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LedesCodeType",
      name: "LedesCodeType",
      component: () =>
        import("@/views/pages/Finance/Definitions/LedesCodeType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/LedesType",
      name: "LedesType",
      component: () =>
        import("@/views/pages/Finance/Definitions/LedesType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AnnualLeaveReport",
      name: "AnnualLeaveReport",
      component: () =>
        import("@/views/pages/HumanResources/AnnualLeaveReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Reminder",
      name: "Reminder",
      component: () => import("@/views/pages/Reminder.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ReminderReport",
      name: "ReminderReport",
      component: () => import("@/views/pages/Reports/ReminderReportList.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/DailyworkReport",
      name: "DailyworkReport",
      component: () => import("@/views/pages/Reports/DailyworkReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/OvertimeWorkReason",
      name: "OvertimeWorkReason",
      component: () =>
        import("@/views/pages/Definitions/OvertimeWork/OvertimeWorkReason.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/OvertimeWorkType",
      name: "OvertimeWorkType",
      component: () =>
        import("@/views/pages/Definitions/OvertimeWork/OvertimeWorkType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/OvertimeWork",
      name: "OvertimeWorkList",
      component: () =>
        import("@/views/pages/HumanResources/OvertimeWorkList.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/OvertimeWorkListForEmployee",
      name: "OvertimeWorkListForEmployee",
      component: () =>
        import("@/views/pages/HumanResources/OvertimeWorkListForEmployee.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/OvertimeWorkListForManager",
      name: "OvertimeWorkListForManager",
      component: () =>
        import("@/views/pages/Admin/OvertimeWorkListForManager.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceStatus",
      name: "InvoiceStatus",
      component: () =>
        import("@/views/pages/Finance/Definitions/InvoiceStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/BillingStatus",
      name: "BillingStatus",
      component: () =>
        import("@/views/pages/Finance/Definitions/BillingStatus.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceDiscountFormat",
      name: "InvoiceDiscountFormat",
      component: () =>
        import("@/views/pages/Finance/Definitions/InvoiceDiscountFormat.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/CreditCard",
      name: "CreditCard",
      component: () =>
        import("@/views/pages/Finance/Definitions/CreditCard.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/OfficialFee",
      name: "OfficialFee",
      component: () => import("@/views/pages/Finance/OfficialFee.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/JobReport",
      name: "JobReport",
      component: () => import("@/views/pages/HumanResources/JobReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/ClientAccountingCategory",
      name: "ClientAccountingCategory",
      component: () =>
        import("@/views/pages/Definitions/ClientAccountingCategory.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Payment",
      name: "Payment",
      component: () => import("@/views/pages/Finance/Accounting/Payment.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceAgingReport",
      name: "InvoiceAgingReport",
      component: () =>
        import("@/views/pages/Finance/Accounting/InvoiceAgingReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkObjectionToOpposingViewStatus",
      name: "TrademarkObjectionToOpposingViewStatus",
      component: () =>
        import(
          "@/views/pages/Definitions/TrademarkObjectionToUsStatuses/TrademarkObjectionToOpposingViewStatus.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkObjectionToUsCaseDecision",
      name: "TrademarkObjectionToUsCaseDecision",
      component: () =>
        import(
          "@/views/pages/Definitions/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsCaseDecision.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkObjectionToUsDecision",
      name: "TrademarkObjectionToUsDecision",
      component: () =>
        import(
          "@/views/pages/Definitions/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsDecision.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkObjectionToUsStatus",
      name: "TrademarkObjectionToUsStatus",
      component: () =>
        import(
          "@/views/pages/Definitions/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsStatus.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkObjectionToUsYidkStatus",
      name: "TrademarkObjectionToUsYidkStatus",
      component: () =>
        import(
          "@/views/pages/Definitions/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsYidkStatus.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkProvisionalStatus",
      name: "TrademarkProvisionalStatus",
      component: () =>
        import(
          "@/views/pages/Definitions/TrademarkProvisionalStatuses/TrademarkProvisionalStatus.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkProvisionalCaseInstruction",
      name: "TrademarkProvisionalCaseInstruction",
      component: () =>
        import(
          "@/views/pages/Definitions/TrademarkProvisionalStatuses/TrademarkProvisionalCaseInstruction.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkTransactionReport",
      name: "TrademarkTransactionReport",
      component: () =>
        import("@/views/pages/Trademark/TrademarkTransactionReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NationalTrademarkTransactionReport",
      name: "NationalTrademarkTransactionReport",
      component: () =>
        import(
          "@/views/pages/Trademark/NationalTrademarkTransactionReport.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkBulletinCheck",
      name: "TrademarkBulletinCheck",
      component: () =>
        import("@/views/pages/BulletinReport/TrademarkBulletinCheck.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/MailHistory",
      name: "MailHistory",
      component: () => import("@/views/pages/MailHistory.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AgentTransactionsReport",
      name: "AgentTransactionsReport",
      component: () =>
        import("@/views/pages/Reports/AgentTransactionsReport.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/InvoiceApproval",
      name: "InvoiceApproval",
      component: () =>
        import("@/views/pages/Finance/Accounting/InvoiceApproval.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/EInvoice",
      name: "EInvoice",
      component: () => import("@/views/pages/Finance/Accounting/EInvoice.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Etebs",
      name: "Etebs",
      component: () => import("@/views/pages/Tpmk/Etebs.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/AccountingBooks",
      name: "AccountingBooks",
      component: () =>
        import("@/views/pages/Finance/Accounting/AccountingBooks.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/Statement",
      name: "Statement",
      component: () => import("@/views/pages/Finance/Accounting/Statement.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/TrademarkOppositionStatus",
      name: "TrademarkOppositionStatus",
      component: () =>
        import(
          "@/views/pages/Definitions/Status/TrademarkOppositionStatus.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/NationalTrademarkSearchStatus",
      name: "NationalTrademarkSearchStatus",
      component: () =>
        import(
          "@/views/pages/Definitions/Status/NationalTrademarkSearchStatus.vue"
        ),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/BusinessPartnerType",
      name: "BusinessPartnerType",
      component: () =>
        import("@/views/pages/Definitions/BusinessPartnerType.vue"),
      meta: {
        rule: "logged",
      },
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
    },
    {
      path: "/ForgotPassword",
      name: "ForgotPassword",
      component: () => import("@/views/ForgotPassword.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
        adminAuth: true,
        residentAuth: false,
      },
    },
    {
      path: "/ChangePassword",
      name: "ChangePassword",
      component: () => import("@/views/ChangePassword.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
    },
    {
      path: "/exit",
      name: "exit",
      component: () => import("@/views/Exit.vue"),
      meta: {
        layout: "full",
        rule: "nonlogin",
      },
      // redirect: '/'
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("accessToken");
  // to.meta.roles.includes(store.state.user.rol)
  if (to.meta.rule == "nonlogin") {
    next();
  } else if (to.meta.rule == "logged" && loggedIn) {
    const RoleId = localStorage.getItem("RoleId");
    if (to.meta.roleId != undefined) {
      if (to.meta.roleId == RoleId) {
        next();
      } else {
        return next("Unauthorized");
      }
    }
    next();
  } else {
    localStorage.clear();
    return next("/");
  }
});

export default router;
