<template>
<div>
    <!-- modal -->
    <b-modal id="businessPartnerAddressFormModal" centered size="lg" no-close-on-backdrop ref="businessPartnerAddressFormModal" :title=" $t('businessPartnerAddress.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-4 mt-1">
                    <b-form>
                        <label for="type-option">{{ $t('businessPartnerAddress.country') }}</label>
                        <validation-provider :name=" $t('businessPartnerAddress.country')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-select size="sm" v-model="countrySelect" value-field="id" text-field="name" :options="countryOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-4 mt-1">
                    <b-form>
                        <label for="type-option">{{ $t('businessPartnerAddress.address_type') }}</label>
                        <validation-provider :name=" $t('businessPartnerAddress.address_type')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-select size="sm" v-model="addressTypeSelect" value-field="id" text-field="name" :options="addressTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-4 mt-1">
                    <b-form>
                        <label for="businessPartnerAddress-zip-code-input">{{ $t('businessPartnerAddress.zip_code') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('businessPartnerAddress.zip_code')" rules="required">
                                <b-form-input size="sm" id="businessPartnerAddress-zip-code-input" v-model="zipCode" maxlength="5" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="businessPartnerAddress-address-input">{{ $t('businessPartnerAddress.address') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('businessPartnerAddress.address')" rules="required">
                                <b-form-textarea id="businessPartnerAddress-address-input" maxlength="250" :placeholder=" $t('businessPartnerAddress.address')" v-model="address" rows="3" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getBusinessPartnerAddressList: {
            type: Function
        },
        getBusinessPartnerList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            businessPartnerId: 0,
            addressTypeOption: [],
            addressTypeSelect: 0,
            countryOption: [],
            countrySelect: 0,
            address: '',
            zipCode: ''
        }
    },
    methods: {
        showInfo(id, businessPartnerId) {
            this.$refs['businessPartnerAddressFormModal'].show()
            this.id = id
            this.businessPartnerId = businessPartnerId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'BusinessPartnerAddress'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                this.address = response.data.businessPartnerAddress.address
                this.zipCode = response.data.businessPartnerAddress.zipCode
                this.countryOption = response.data.countryList
                if (response.data.businessPartnerAddress.countryId > 0) {
                    this.countrySelect = response.data.businessPartnerAddress.countryId

                } else {
                    this.countrySelect = 0
                }
                this.addressTypeOption = response.data.addressTypeList
                if (response.data.businessPartnerAddress.addressTypeId > 0) {
                    this.addressTypeSelect = response.data.businessPartnerAddress.addressTypeId

                } else {
                    this.addressTypeSelect = 0
                }
                this.addressTypeOption.splice(0, 0, this.$nullSelected())
                this.countryOption.splice(0, 0, this.$nullSelected())

            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                businessPartnerId: this.businessPartnerId,
                countryId: this.countrySelect,
                addressTypeId: this.addressTypeSelect,
                address: this.address,
                zipCode: this.zipCode
            }
            const controller = {
                name: 'BusinessPartnerAddress'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('businessPartnerAddressFormModal')
                            this.getBusinessPartnerAddressList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        clear() {
            this.address = '',
                this.zipCode = '',
                this.countrySelect = 0,
                this.addressTypeSelect = 0
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
