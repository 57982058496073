<template>
<div>
    <b-modal id="totalImageUploadFormModal" centered size="lg" no-close-on-backdrop ref="totalImageUploadFormModal" :title="$t('totalImageUpload.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-6">
                    <b-form style="margin-top: 0.2em">
                        <validation-provider :name="$t('totalImageUpload.record_no')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-group :label="$t('totalImageUpload.record_no')" label-for="total-image-upload-record-no-input"
                              :state="errors.length > 0 ? false : null">
                              <v-select id="total-image-upload-record-no-input" size="sm" v-model="recordName" label="recordName" @input="setValues" @search="onFastSearch"
                              :options="searchRecordOption.map(option => ({ ...option, recordName: option.recordName}))" >
                              </v-select>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                    </b-form>

                </b-col>   
                <!-- //STOCK-1176 -->
                <b-col class="col-6">
                    <label for="document-date">{{ $t('documents.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <b-form>
                        <validation-provider :name=" $t('totalImageUpload.documents_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <label for="total-image-upload-documents-type-input">{{ $t('totalImageUpload.documents_type') }}</label>
                            <b-form-select :disabled="moduleId == 0" @change="getDocumentDetailList()" size="sm" :state="errors.length > 0 ? false:null" v-model="documentTypeSelect" value-field="id" text-field="name" :options="documentTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col>
                    <b-form>
                        <validation-provider :name=" $t('totalImageUpload.documents_content')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <label for="total-image-upload-documents-content-input">{{ $t('totalImageUpload.documents_content') }}</label>
                            <b-form-select :disabled="documentTypeSelect == 0" size="sm" :state="errors.length > 0 ? false:null" v-model="documentContentSelect" value-field="id" text-field="name" :options="documentContentOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form>
                        <label for="total-image-upload-note-input">{{ $t('totalImageUpload.note') }}</label>
                        <b-form-group>
                            <b-form-textarea id="total-image-upload-note-input" :placeholder=" $t('totalImageUpload.note')" v-model="note" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <label for="total-image-upload-file-upload-input">{{ $t('totalImageUpload.file_upload') }}</label>
                    <b-form-file :placeholder="$t('totalImageUpload.select_images')" :drop-placeholder="$t('totalImageUpload.drop')" :browse-text="$t('totalImageUpload.select')" multiple @change="uploadMultipleFiles" />
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    data() {
        return {
            id: 0,
            recordId: 0,
            moduleId: 0,
            recordName:0,
            searchRecordOption:[],
            documentTypeSelect: 0,
            documentTypeOption: [],
            documentContentSelect: 0,
            documentContentOption: [],
            name: '',
            note: '',
            documentFile: '',
            documentType: '',
            documentName: '',
            documentTypeList: [],
            documentList: Object,
            date:"",
            fileName: '',
            fileType: '',
            fileBase: ''
        }
    },
    mounted() {
        this.getDocumentTypeList()
    },
    methods: {
        showInfo() {
            if(this.date == ""){
                setTimeout(() => {  this.date = new Date().toJSON() }, 200);
            }
            this.documentTypeSelect = 0
            this.documentContentSelect = 0
            this.note = ''
            this.documentList = []
            this.$refs['totalImageUploadFormModal'].show()
        },
        async uploadFile(e) {
            this.documentList = null
            var fileInfoList = await this.$getFileInfo(e.target.files)
            fileInfoList.forEach(fileInfo => {
                this.documentList.push(fileInfo.name, fileInfo.contentType, fileInfo.base64)
                this.documentName = fileInfo.name
                this.documentType = fileInfo.contentType
                this.documentFile = fileInfo.base64
            })
        },
        async uploadMultipleFiles(e) {
            this.documentList = [];
            const fileInfoList = await this.$getMultipleFilesInfo(e.target.files);

            fileInfoList.forEach(fileInfo => {
                this.documentList.push({
                    name: fileInfo.name,
                    contentType: fileInfo.contentType,
                    base64: fileInfo.base64
                });
            });
            if (this.documentList.length > 0) {
                const firstFile = this.documentList[0];
                this.documentName = firstFile.name;
                this.documentType = firstFile.contentType;
                this.documentFile = firstFile.base64;
            }
        },
        getDocumentDetailList() {
            this.documentContentOption = []
            if (this.documentTypeSelect > 0) {
                const data = {
                    id: this.documentTypeSelect
                }
                const controller = {
                    name: 'DocumentTypeDetail',
                    actionName: 'GetDocumentTypeDetailListByDocumentTypeId'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        response.data.documentTypeDetailList.forEach(element => {
                            this.documentContentOption.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
                this.documentContentOption.splice(0, 0, this.$nullSelected())
            }
        },
        findDocumentTypeList() {
            this.documentTypeOption = []
            if (this.moduleId > 0) {
                this.documentTypeOption = this.documentTypeList.filter(p => p.moduleId == this.moduleId)
                this.documentTypeOption.splice(0, 0, this.$nullSelected())
            }
        },
        onFastSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.fastSearch(loading, search, this);
            }
        },
        fastSearch(loading, search) {
            if (search.length > 2) {
                const data = {
                    recordNo: search,
                    isOnlyOperationRecord : true
                }

                const controller = {
                    name: 'Module',
                    actionName: 'GetRecordListByRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.searchRecordOption = response.data.recordList
                    loading(false)
                })
            }
        },
        setValues(event) {
            this.moduleId = event.moduleId
            this.recordId = event.recordId
            if(this.moduleId > 0 && this.recordId > 0){
                this.findDocumentTypeList()
            }            
        },
        getDocumentTypeList() {
            this.documentTypeList = []
            const controller = {
                name: 'DocumentType'
            }
            const payload = {
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.documentTypeList.forEach(element => {
                        this.documentTypeList.push(element)                        
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {  
            this.$refs.simpleRules.validate().then(success => {
                if (this.documentFile == '') {
                    this.$WarningAlert(this.$t('alerts.base64_null'))
                }
                else
                {
                    if (success) {
                        let loader = this.$loading.show()
                        const documentList= []
                        this.documentList.forEach(fileInfo => {
                            this.fileName = fileInfo.name
                            this.fileType = fileInfo.contentType
                            this.fileBase = fileInfo.base64
                            documentList.push({ id: 0, moduleId: this.moduleId, recordId: this.recordId, jurisdictionId: 1, documentTypeDetailId: this.documentContentSelect,
                                documentTypeId: this.documentTypeSelect, note: this.note, name: this.fileName, type: this.fileType, base64: this.fileBase, date: this.date,
                                employeeId: localStorage.getItem('EmployeeId')});
                        })

                        const data = {documentList : documentList}

                        const controller = { name: 'Document', actionName: 'SaveMultiDocument' }
                        const payload = { data: data, controller: controller}

                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('totalImageUploadFormModal')
                                loader.hide()
                                this.moduleId = 0
                                this.documentTypeSelect = 0
                                this.documentContentSelect = 0
                                this.note = ''
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                                loader.hide()
                            }
                        })
                    }
                }
            })
        },
    }
}
</script>

<style>
[dir] .vs__selected-options {
    padding: 0.2px;
    font-size: 0.9em;
}
</style>
