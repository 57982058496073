<template>
<div>
    <business-partner-form :getBusinessPartnerList="getBusinessPartnerList" ref="businessPartnerFormModal"></business-partner-form>
    <b-row class="align-items-center">
        <b-col class="col-md-2 mb-1">
            <label for="business-partner-type-input">{{ $t('businessPartner.business_partner_type') }}</label>
            <div class="d-flex align-items-center">
                <b-form-select @input="getBusinessPartnerList()" v-model="businessPartnerTypeSelect" size="sm" value-field="id" text-field="name" :options="businessPartnerTypeOption" />
            </div>
        </b-col>
        <b-col class="col-md-2 mb-1">
            <label for="business-partner-name-input">{{ $t('businessPartner.name') }}</label>
            <div class="d-flex align-items-center">
                <b-form-input id="business-partner-name-input" v-model="name" size="sm" />
            </div>
        </b-col>
        <b-col class="col-md-2 mb-1">
            <label for="business-partner-code-input">{{ $t('businessPartner.code') }}</label>
            <div class="d-flex align-items-center">
                <b-form-input id="business-partner-code-input" v-model="code" size="sm" />
            </div>
        </b-col>
        <b-col class="col-md-2 mb-1">
            <label for="business-partner-accounting-code-input">{{ $t('businessPartner.number') }}</label>
            <div class="d-flex align-items-center">
                <b-form-input id="business-partner-code-input" v-model="accountingCode" size="sm" />
            </div>
        </b-col>
        <b-col class="col-md-4">
            <div style="float:right;" class="custom-search d-flex justify-content-end">     
                <b-button style="float:right;" class="ml-05" size="sm" variant="outline-primary" v-on:keyup.enter="getBusinessPartnerList()" @click="getBusinessPartnerList()">{{ $t('others.list') }}</b-button>       
                <b-button style="float:right;" class="ml-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('businessPartner.add') }}</b-button>
                <b-button style="float:right;" class="ml-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>  
            </div>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="businessPartnerTable" ref="businessPartnerTable" :columns="columns" :rows="rows" :sort-options="{
        enabled: false,
      }" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm }" :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('businessPartner.name') }}
            </span>
            <span v-else-if="props.column.field === 'code'" class="text-nowrap">
                {{ $t('businessPartner.code') }}
            </span>
            <span v-else-if="props.column.field === 'number'" class="text-nowrap">
                {{ $t('businessPartner.accountingNUmber') }}
            </span>
            <span v-else-if="props.column.field === 'taxNumber'" class="text-nowrap">
                {{ $t('businessPartner.tax_number') }}
            </span>
            <span v-else-if="props.column.field === 'businessPartnerType'" class="text-nowrap">
                {{ $t('businessPartner.business_partner_type') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: BusinessPartner Transactions Name -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <!-- <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button> -->
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getBusinessPartnerList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    props: {
        subsidiaryLedgerId: {
            type: Number
        }
    },
    data() {
        return {
            pageLength: 10,
            businessPartnerTypeSelect: 0,
            businessPartnerTypeOption: [],
            columns: [{
                    label: this.$t('businessPartner.name'),
                    field: `name`,
                },
                {
                    label: this.$t('businessPartner.code'),
                    field: `code`,
                },
                {
                    label: this.$t('businessPartner.number'),
                    field: `accountingNUmber`,
                },
                {
                    label: this.$t('businessPartner.business_partner_type'),
                    field: `businessPartnerType`,
                },
                {
                    label: this.$t('businessPartner.tax_number'),
                    field: `taxNumber`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            total: 0,
            taxNumber: '',
            code: '',
            accountingCode: '',
            number: '',
            name: '',
            pageLength: 10,
            pageNo: 1
        }
    },
    mounted() {
        this.getBusinessPartnerList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'BusinessPartner'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getBusinessPartnerList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getBusinessPartnerList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.businessPartnerFormModal.showInfo(id, this.subsidiaryLedgerId)
        },
        getBusinessPartnerList(id) {
            this.rows = []
            this.id = id
            const controller = {
                name: 'BusinessPartner'
            }
            const data = {
                subsidiaryLedgerId: this.subsidiaryLedgerId,
                businessPartnerTypeId: this.businessPartnerTypeSelect,
                name: this.name,
                code: this.code,
                accountingCode: this.accountingCode,
                taxNumber: this.taxNumber,
                number: this.number,
                pageNo: this.pageNo,
                pageLength: this.pageLength
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.businessPartnerTypeOption = response.data.businessPartnerTypeList
                    this.total = response.data.total
                    this.businessPartnerTypeOption.splice(0, 0, this.$nullSelected("name"))
                    response.data.businessPartnerList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'BusinessPartner',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("BusinessPartner", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
