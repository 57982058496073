<template>
<div>
    <client-form :getClientList="getClientList" ref="clientFormModal"></client-form>
    <agent-transactions-report-trademark-list ref="agentTransactionsReportTrademarkList"></agent-transactions-report-trademark-list>
    <agent-transactions-report-national-trademark-list ref="agentTransactionsReportNationalTrademarkList"></agent-transactions-report-national-trademark-list>
    <agent-transactions-report-trademark-provisional-list ref="agentTransactionsReportTrademarkProvisionalList"></agent-transactions-report-trademark-provisional-list>
    <agent-transactions-report-trademark-objection-to-us-list ref="agentTransactionsReportTrademarkObjectionToUsList"></agent-transactions-report-trademark-objection-to-us-list>
    <agent-transactions-report-trademark-opposition-list ref="agentTransactionsReportTrademarkOppositionList"></agent-transactions-report-trademark-opposition-list>
    <agent-transactions-report-patent-list ref="agentTransactionsReportPatentList"></agent-transactions-report-patent-list>
    <agent-transactions-report-design-list ref="agentTransactionsReportDesignList"></agent-transactions-report-design-list>
    <agent-transactions-report-design-opposition-list ref="agentTransactionsReportDesignOppositionList"></agent-transactions-report-design-opposition-list>
    <agent-transactions-report-domain-list ref="agentTransactionsReportDomainList"></agent-transactions-report-domain-list>

    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-8">
            <div class="mr-1">
                <label for="client-name-input">{{ $t('others.search_name') }}</label>
                <b-form-input id="client-name-input" size="sm" v-model="name" v-on:keyup.enter="getClientList()" :placeholder="$t('others.search_name')" type="text" class="d-inline-block mb-2" />
            </div>
            <div class="mr-1">
                <label for="client-country-input">{{ $t('client.country') }}</label>
                <b-form-select id="client-country-input" v-model="countrySelect" size="sm" value-field="id" text-field="name" :options="countryOption" class="mb-2" />
            </div>

            <b-form-checkbox v-model="isAgent" class="mr-3">{{ $t('client.is_agent') }}</b-form-checkbox>
            <b-form-checkbox v-model="isOwner">{{ $t('client.is_owner') }}</b-form-checkbox>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4">
            <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getClientList()" v-on:keyup.enter="getClientList()">{{ $t('client.list') }}</b-button>
            <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('client.client_add') }}</b-button>
            <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="clientTable" ref="clientTable" :columns="columns" :rows="rows" :sort-options="{enabled: false}" :search-options="{ enabled: true, externalQuery: searchTerm}" 
    styleClass="vgt-table condensed tableSize" :select-options="{enabled: false, selectOnCheckboxOnly: true, selectionInfoClass: 'custom-class', selectionText: 'rows selected', 
    clearSelectionText: 'clear', disableSelectInfo: true, selectAllByGroup: true}" :pagination-options="{ enabled: true, perPage: pageLength}" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('client.client_name') }}
            </span>
            <span v-else-if="props.column.field === 'clientAccountingList'">
                <b-row>
                    <b-col>
                        <span>
                            {{ $t('client.trademark_active') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.national_trademark') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.appeal_decision') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.incoming_decision') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.trademark_opposition') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.lawMatter') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.patent') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.design') }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            {{ $t('client.design_opposition') }}
                        </span>
                    </b-col>
                    <b-col>
                        {{ $t('client.domain') }}
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Client Transactions Name -->
            <span v-if="props.column.field === 'name'">
                <b-col>
                    <b-row>
                        <tr v-if="props.row.isAgent === true && props.row.isOwner === false">
                            <span style="font-size: smaller"><b>{{ props.row.name }}</b>
                            </span>
                        </tr>
                        <tr v-else-if="props.row.isOwner === true && props.row.isAgent === false">
                            <span style="font-size: smaller"><b>{{ props.row.name }}</b>
                            </span>
                        </tr>
                        <tr v-else-if="props.row.isAgent === true && props.row.isOwner === true">
                            <span style="font-size: smaller"><b>{{ props.row.name }}</b>
                            </span>
                        </tr>
                    </b-row>
                    <b-row v-if="props.row.clientAddress !== null">
                        <span>
                            <tr v-if="clientAddress.address !== null" style="font-size: smaller">{{ props.row.clientAddress.address}}
                            </tr>
                            <tr v-if="clientAddress.country !== null" style="font-size: smaller">{{ props.row.clientAddress.country}}
                            </tr>
                        </span>
                    </b-row>
                </b-col>
            </span>
            <span style="text-align: right" v-else-if="props.column.field === 'accounting'">
                <b-col v-if="props.row.isOwner === true">
                    <b-badge style="color:black;" pill variant="success">{{$t('client.owner')}}</b-badge>
                </b-col>
                <b-col >
                    <b-row v-for="clientAccounting in props.row.clientAccountingList" v-bind:key="clientAccounting.code">
                        <label> {{clientAccounting.number }} <b-badge  style="color:black;" pill variant="secondary">{{clientAccounting.code}} </b-badge></label>
                    
                        <!-- <b-col class="col-md-4">
                            <span v-if="clientAccounting.note != null" v-b-popover.hover.top="clientAccounting.note" style="font-size: x-small;cursor: pointer;" class="text-nowrap">{{clientAccounting.note.substr(0, 50)}}</span>
                        </b-col> -->


                    </b-row>
                </b-col>
            </span>
            <!-- Column: AccountingList -->
            <span v-else-if="props.column.field === 'clientAccountingList'">
                <!-- STOCK-1151 Nevzat 11.09.2024  -->
                <b-row v-if="props.row.isOwner === true">
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.trademarkActiveCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 1, props.row.id)">{{ props.row.clientOwnershipInformation.trademarkActiveCount }}</span> / 
                        <span :style="[ props.row.clientOwnershipInformation.trademarkPassiveCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 2, props.row.id)">{{ props.row.clientOwnershipInformation.trademarkPassiveCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.nationalTrademarkCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 3, props.row.id)">{{ props.row.clientOwnershipInformation.nationalTrademarkCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.trademarkProvisionalCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 4, props.row.id)">{{ props.row.clientOwnershipInformation.trademarkProvisionalCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.trademarkObjectionToUsCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 5, props.row.id)">{{ props.row.clientOwnershipInformation.trademarkObjectionToUsCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.trademarkOppositionCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 6, props.row.id)">{{ props.row.clientOwnershipInformation.trademarkOppositionCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.lawMatterCount > 0 ? { 'color': 'red' } : '']" style="font-weight:bold">{{ props.row.clientOwnershipInformation.lawMatterCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.patentCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 7, props.row.id)">{{ props.row.clientOwnershipInformation.patentCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.designCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 9, props.row.id)">{{ props.row.clientOwnershipInformation.designCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.designOppositionCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 10, props.row.id)">{{ props.row.clientOwnershipInformation.designOppositionCount }}</span>
                    </b-col>
                    <b-col>
                        <span :style="[ props.row.clientOwnershipInformation.domainCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(1, 11, props.row.id)">{{ props.row.clientOwnershipInformation.domainCount }}</span>
                    </b-col>
                </b-row>

                <b-row v-for="clientAccounting in props.row.clientAccountingList" v-bind:key="clientAccounting.code">
                    <b-col class="col-12">
                        <b-row>
                            <b-col>
                                <span :style="[ clientAccounting.trademarkActiveCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 1, clientAccounting.id)">{{ clientAccounting.trademarkActiveCount }}</span>
                                /<span :style="[ clientAccounting.trademarkPassiveCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 2, clientAccounting.id)">{{ clientAccounting.trademarkPassiveCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.nationalTrademarkCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 3, clientAccounting.id)">{{ clientAccounting.nationalTrademarkCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.trademarkProvisionalCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 4, clientAccounting.id)">{{ clientAccounting.trademarkProvisionalCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.trademarkObjectionToUsCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 5, clientAccounting.id)">{{ clientAccounting.trademarkObjectionToUsCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.trademarkOppositionCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 6, clientAccounting.id)">{{ clientAccounting.trademarkOppositionCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.lawMatterCount > 0 ? { 'color': 'red' } : '']" style="font-weight:bold">{{ clientAccounting.lawMatterCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.patentCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 7, clientAccounting.id)">{{ clientAccounting.patentCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.designCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 9, clientAccounting.id)">{{ clientAccounting.designCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.designOppositionCount > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 10, clientAccounting.id)">{{ clientAccounting.designOppositionCount }}</span>
                            </b-col>
                            <b-col>
                                <span :style="[ clientAccounting.domain > 0 ? { 'color': 'red' } : '']" style="cursor: pointer;font-weight:bold" @click="showDetailList(0, 11, clientAccounting.id)">{{ clientAccounting.domain }}</span>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </span>

            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getClientList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            name: "",
            // email:"",
            countrySelect: 0,
            countryOption: [],
            isAgent: false,
            isOwner: false,
            total: 0,
            pageLength: 10,
            pageNo: 1,
            dir: false,
            clientAddress: '',
            columns: [
                {
                    label: this.$t('client.client_name'),
                    field: `name`,
                },
                {
                    label: this.$t('client.accounting'),
                    field: `accounting`,
                },
                {
                    field: `clientAccountingList`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getClientList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'Client'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getClientList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getClientList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.clientFormModal.showInfo(id)
        },
        getClientList() {
            this.rows = []
            const controller = {
                name: 'Client'
            }
            const data = {
                name: this.name,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                isAgent: this.isAgent,
                isOwner: this.isOwner,
                countryId: this.countrySelect
            }
            // , email: this.email 
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.countrySelect = this.countrySelect
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())
                    this.total = response.data.total

                    response.data.clientList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        showDetailList(clientType, recordType, id) {
            if (clientType == 0) {
                if (recordType == 1 || recordType == 2) {
                    this.$refs.agentTransactionsReportTrademarkList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 7) {
                    this.$refs.agentTransactionsReportPatentList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 9) {
                    this.$refs.agentTransactionsReportDesignList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 3) {
                    this.$refs.agentTransactionsReportNationalTrademarkList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 4) {
                    this.$refs.agentTransactionsReportTrademarkProvisionalList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 5) {
                    this.$refs.agentTransactionsReportTrademarkObjectionToUsList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 6) {
                    this.$refs.agentTransactionsReportTrademarkOppositionList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 10) {
                    this.$refs.agentTransactionsReportDesignOppositionList.getAgentTransactionsReportList(recordType, id)
                } else if (recordType == 11) {
                    this.$refs.agentTransactionsReportDomainList.getAgentTransactionsReportList(recordType, id)
                }
            }

            if (clientType == 1) {
                if (recordType == 1 || recordType == 2) {
                    this.$refs.agentTransactionsReportTrademarkList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 3) {
                    this.$refs.agentTransactionsReportNationalTrademarkList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 4) {
                    this.$refs.agentTransactionsReportTrademarkProvisionalList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 5) {
                    this.$refs.agentTransactionsReportTrademarkObjectionToUsList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 6) {
                    this.$refs.agentTransactionsReportTrademarkOppositionList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 7) {
                    this.$refs.agentTransactionsReportPatentList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 9) {
                    this.$refs.agentTransactionsReportDesignList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 10) {
                    this.$refs.agentTransactionsReportDesignOppositionList.getOwnerTransactionsReportList(recordType, id)
                } else if (recordType == 11) {
                    this.$refs.agentTransactionsReportDomainList.getOwnerTransactionsReportList(recordType, id)
                }
            }
        },
        exportExcel() {
            var controller = {
                name: 'Client',
                actionName: 'CanExport'
            }
            var data = {
                name: this.name,
                isAgent: this.isAgent,
                isOwner: this.isOwner,
                countryId: this.countrySelect
            }
            // , email: this.email 
            var payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Müvekkil", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
