<template>
    <div>
        <b-modal id="xmlOwnerClientSearchListCallForm" centered size="xl" no-close-on-backdrop ref="xmlOwnerClientSearchListCallForm" hide-footer>
            <xml-owner-client-search-list :xmlOwnerName="this.xmlOwnerName"></xml-owner-client-search-list>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {},
        data() {
            return {
                xmlOwnerName : '',
            }
        },
        methods: {
            showInfo(xmlOwnerName) {
                this.xmlOwnerName = xmlOwnerName
                this.$refs["xmlOwnerClientSearchListCallForm"].show()
            }
        }
    }
    </script>
    