<template>
<div>
    <client-form ref="clientFormModal"></client-form>
    <b-row>
        <b-col class="col-12">
            <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search_name')" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>
            <!-- <div style="float:right;" class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                        </div>
                    </b-form-group>
                </div> -->
        </b-col>
    </b-row>
    <vue-good-table id="clientInvoiceReportTable" ref="clientInvoiceReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'ownerName'" class="text-nowrap">
                {{ $t('search_xml_owner.owner_name') }}
            </span>
            <span v-else-if="props.column.field === 'address'" class="text-nowrap">
                {{ $t('search_xml_owner.address') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Process -->
            <span v-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-05" size="sm" @click="getClientInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

    
<script>
export default {
    props: {
        xmlOwnerName: String,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            pageNo: 1,
            searchTerm: '',
            columns: [{
                    label: this.$t('search_xml_owner.owner_name'),
                    field: `ownerName`,
                },
                {
                    label: this.$t('search_xml_owner.address'),
                    field: `address`,
                },

                {
                    label: 'Process',
                    field: 'process',
                    width: '7em'
                },
            ],
            rows: [],
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        // async getInfo(id) {
        //     this.$refs.search_xml_ownerFormModal.showInfo(id)
        // },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        getList() {
            this.rows = []
            const controller = {
                name: 'Client',
                actionName: 'SearchXmlOwnerClientList'
            }
            const data = {
                xmlOwnerName: this.xmlOwnerName,
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.searchXmlOwnerClientList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        // exportExcel() {
        //     const controller = {
        //         name: 'Client',
        //         actionName: 'CanExport'
        //     }
        //     const data = {
        //         columnList: this.columns,
        //         DataList: this.rows
        //     }
        //     const payload = {
        //         data: data,
        //         controller: controller
        //     }
        //     this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        //         if (response.data.resultStatus == true) {
        //             this.$downloadFile("ClientInvoicesReport", response.data.base64, 'xlsx')
        //         } else {
        //             this.$WarningAlert(response.data.resultMessage)
        //         }
        //     })
        // }
    }
}
</script>

    
<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
