<template>
<div>
    <client-form ref="clientFormModal"></client-form>
    <xml-owner-client-search-list-call-form ref="xmlOwnerClientSearchListCallFormModal"></xml-owner-client-search-list-call-form>
    <!-- modal -->
    <b-modal id="epoXmlFormModal" centered size="xl" no-close-on-backdrop ref="epoXmlFormModal" :title="$t('epoXml.epo_xml_info')" :cancel-title="$t('others.cancel_title')" cancel-variant="outline-secondary" :ok-title="$t('others.ok_title')" @ok.prevent="addOrUpdate">
        <!--Patent-->
        <agent-note-form ref="agentNoteForm"></agent-note-form>
        <b-card :title="$t('epoXml.patent_info')">
            <validation-observer ref="simpleRules">
                <b-list-group>
                    <b-row>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_no') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ this.patentNo }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.ep_patent_no') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ this.epPatentNo }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_ep_b1_pub_date') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ $formatFn(this.epB1PubDate) }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_ipc_code') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ this.ipcCode }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_ep_application_no') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ this.epApplicationNo }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_ep_application_date') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ $formatFn(this.epApplicationDate) }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_date_of_grant') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ $formatFn(this.dateOfGrant) }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_is_tr_designation') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <b-form-checkbox class="custom-control-success" name="country-isTrMain-input" id="country-isTrMain-input" v-model="this.isTrDesignation" switch disabled>
                                            <span class="switch-icon-left">
                                                <feather-icon icon="CheckIcon" />
                                            </span>
                                            <span class="switch-icon-right">
                                                <feather-icon icon="XIcon" />
                                            </span>
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-4 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.last_application_date') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ $formatFn(this.lastApplicationDate) }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-12 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.patent_title_en') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ this.titleEn }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-6 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.client_name') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ this.clientName }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-6 mb-1">
                            <b-list-group-item class="flex-column align-items-start" :key="patentOwnerList.id" v-for="owner in this.patentOwnerList ">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.owner_name') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ owner.name }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-md-6 mb-1">
                            <b-list-group-item class="flex-column align-items-start">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.client_address') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ this.clientAddress }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                        <b-col class="col-md-6 mb-1">
                            <b-list-group-item class="flex-column align-items-start" :key="patentOwnerList.id" v-for="owner in this.patentOwnerList ">
                                <b-row>
                                    <b-col>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h6>
                                                {{ $t('epoXml.owner_address') }}
                                            </h6>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        {{ owner.address }}
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-lg-6">
                            <label for="patent-agent-input"> {{ $t('patent.agent') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('findRecord.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="patent-agent-input" :state="errors.length > 0 ? false:null" style="width: 100%;">
                                        <v-select class="agentInputCSS" style="width: 100%;" id="patent-agent-input" v-model="patentAgentSelect" :options="patentAgentOption" @input="setClientId" label="clientName" @search="onSearchAgent" />
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-dropdown style="width: 4em; height: 2em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_client')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(patentAgentSelect.clientAccountingId)">
                                            <span>{{this.$t('others.show_note')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(patentAgentSelect.clientId)">
                                            <span>{{this.$t('others.show_agent')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col class="col-lg-6">
                            <label for="patent-owner-input"> {{ $t('patentOwner.owner_name') }} </label>
                            <b-input-group>
                                <validation-provider :name=" $t('patentOwner.owner_name')" #default="{ errors }" :rules="{ excluded: 0 }">
                                    <b-form-group label-for="patent-owner-input" :state="errors.length > 0 ? false:null" style="width: 100%;">
                                        <v-select class="agentInputCSS" style="width: 100%;" id="patent-owner-input" v-model="patentOwnerSelect" :options="patentOwnerOption" label="name" @search="onSearchOwner" />
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-input-group-append>
                                    <b-dropdown style="width: 4em; height: 2em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{this.$t('others.add_owner')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(patentOwnerSelect.clientAccountingId)">
                                            <span>{{this.$t('others.show_note')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(patentOwnerSelect.clientId)">
                                            <span>{{this.$t('others.show_owner')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="searchXmlOwnerClientList()">
                                            <span>{{this.$t('others.search_owner')}}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="saveOwnerFromXml()">
                                            <span>{{this.$t('others.save_owner_from_xml')}}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-list-group>
            </validation-observer>
        </b-card>
        <!--Patent Division-->
        <b-card :title="$t('epoXml.division_info')">
            <b-col class="col-md-12 mb-1">
                <vue-good-table id="departmentTable" ref="departmentTable" :columns="columns" :rows="this.patentDivisionList" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: false,
          }" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
          enabled: false,
          }" theme="my-theme">
                    <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                    </div>
                    <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'kind'" class="text-nowrap">
                            {{ $t('epoXml.division_kind') }}
                        </span>
                        <span v-else-if="props.column.field === 'docNumber'" class="text-nowrap">
                            {{ $t('epoXml.division_doc_number') }}
                        </span>
                        <span v-else-if="props.column.field === 'country'" class="text-nowrap">
                            {{ $t('epoXml.division_country') }}
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-card>
        <!--Patent Inventor-->
        <b-card :title="$t('epoXml.inventor_info')">
            <b-col class="col-md-12 mb-1">
                <vue-good-table id="departmentTable" ref="departmentTable" :columns="columnsInventor" :rows="this.patentInventorList" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: false,
          }" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
          enabled: false,
          }" theme="my-theme">
                    <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                    </div>
                    <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'name'" class="text-nowrap">
                            {{ $t('epoXml.inventor_name') }}
                        </span>
                        <span v-else-if="props.column.field === 'nationalityIDNumber'" class="text-nowrap">
                            {{ $t('epoXml.inventor_nationality_id_number') }}
                        </span>
                        <span v-else-if="props.column.field === 'country'" class="text-nowrap">
                            {{ $t('epoXml.inventor_country') }}
                        </span>
                        <span v-else-if="props.column.field === 'address'" class="text-nowrap">
                            {{ $t('epoXml.inventor_address') }}
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-card>
        <!--Patent Priority-->
        <b-card :title="$t('epoXml.priority_info')">
            <b-col class="col-md-12 mb-1">
                <vue-good-table id="departmentTable" ref="departmentTable" :columns="columnsPriority" :rows="this.patentPriorityList" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: false,
          }" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
          enabled: false,
          }" theme="my-theme">
                    <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                    </div>
                    <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'no'" class="text-nowrap">
                            {{ $t('epoXml.priority_no') }}
                        </span>
                        <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                            {{ $t('epoXml.priority_date') }}
                        </span>
                        <span v-else-if="props.column.field === 'country'" class="text-nowrap">
                            {{ $t('epoXml.priority_country') }}
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getPatentList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            columns: [{
                    label: this.$t('epoXml.division_kind'),
                    field: 'kind',
                },
                {
                    label: this.$t('epoXml.division_doc_number'),
                    field: `docNumber`,
                },
                {
                    label: this.$t('epoXml.division_country'),
                    field: 'country',
                },
            ],
            columnsInventor: [{
                    label: this.$t('epoXml.inventor_name'),
                    field: 'name',
                },
                {
                    label: this.$t('epoXml.inventor_nationality_id_number'),
                    field: `nationalityIDNumber`,
                },
                {
                    label: this.$t('epoXml.inventor_country'),
                    field: 'country',
                },
                {
                    label: this.$t('epoXml.inventor_address'),
                    field: 'address',
                },
            ],
            columnsPriority: [{
                    label: this.$t('epoXml.priority_no'),
                    field: 'no',
                },
                {
                    label: this.$t('epoXml.priority_date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('epoXml.priority_country'),
                    field: 'country',
                },
            ],
            patentAgentSelect: 0,
            patentAgentOption: [],
            patentOwnerSelect: 0,
            patentOwnerOption: [],
            patentNo: '',
            epPatentNo: '',
            epB1PubDate: '',
            ipcCode: '',
            epApplicationNo: '',
            epApplicationDate: '',
            dateOfGrant: '',
            isTrDesignation: '',
            titleEn: '',
            clientName: '',
            patentOwnerList: [],
            patentDivisionList: [],
            patentInventorList: [],
            patentPriorityList: [],
            patentId: 0,
            address: '',
            nationalityIDNumber: '',
            lastApplicationDate: '',
            clientAddress: '',
        }
    },
    methods: {
        showInfo(xmlData) {
            this.$refs['epoXmlFormModal'].show()
            this.patentAgentSelect = 0,
                this.patentAgentOption = [],
                this.patentOwnerSelect = 0,
                this.patentOwnerOption = [],
                this.patentOwnerList = []
            this.patentDivisionList = []
            this.patentInventorList = []
            this.patentPriorityList = []
            this.patentNo = xmlData.patent.patentNo
            this.epPatentNo = xmlData.patent.epPatentNo
            this.epB1PubDate = xmlData.patent.epB1PubDate
            this.ipcCode = xmlData.patent.ipcCode
            this.epApplicationNo = xmlData.patent.epApplicationNo
            this.epApplicationDate = xmlData.patent.epApplicationDate
            this.dateOfGrant = xmlData.patent.dateOfGrant
            this.isTrDesignation = xmlData.patent.isTrDesignation
            this.titleEn = xmlData.patent.titleEn
            this.clientName = xmlData.xmlClient.name
            this.clientAddress = xmlData.xmlClient.address
            this.patentOwnerList = xmlData.patentOwnerList
            this.lastApplicationDate = xmlData.patent.lastApplicationDate
            if (xmlData.patentDivisionList != null) {
                this.patentDivisionList = xmlData.patentDivisionList

            }
            if (xmlData.patentInventorList != null) {
                this.patentInventorList = xmlData.patentInventorList

            }
            if (xmlData.patentPriorityList != null) {
                this.patentPriorityList = xmlData.patentPriorityList

            }

        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearchAgent(search, loading) {
            if (search.length) {
                loading(true);
                this.searchAgent(loading, search, this);
            }
        },
        searchAgent(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.patentAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        onSearchOwner(search, loading) {
            if (search.length) {
                loading(true);
                this.searchOwner(loading, search, this);
            }
        },
        searchOwner(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    name: search
                }
                const controller = {
                    name: 'Client',
                    actionName: 'GetOwnerClientByName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.patentOwnerOption = response.data.clientList
                    loading(false)
                })
            }
        },
        searchXmlOwnerClientList() {
            if (this.patentOwnerList.length > 0) {
                this.$refs.xmlOwnerClientSearchListCallFormModal.showInfo(this.patentOwnerList[0].name);
            }
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                let data = {
                    id: 0,
                    patentNo: this.patentNo,
                    epApplicationNo: this.epApplicationNo,
                    epApplicationDate: this.epApplicationDate,
                    title: this.titleEn,
                    titleEn: this.titleEn,
                    epB1PubDate: this.epB1PubDate,
                    ipcCode: this.ipcCode,
                    patentPublicationTypeId: 3,
                    clientAccountingId: this.patentAgentSelect.clientAccountingId,
                    isTrDesignation: this.isTrDesignation,
                    patentTypeId: 1,
                    invoiceClientId: this.invoiceClientId = 0,
                    epPatentNo: this.epPatentNo,
                    lastApplicationDate: this.lastApplicationDate
                }

                let controller = {
                    name: 'Patent'
                }
                let payload = {
                    data: data,
                    controller: controller
                }

                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.patentId = response.data.patentId;
                            if (this.patentId > 0) {
                                data = {
                                    id: 0,
                                    patentId: this.patentId,
                                    clientId: this.patentOwnerSelect.id
                                }
                                controller = {
                                    name: 'PatentOwner'
                                }
                                payload = {
                                    data: data,
                                    controller: controller
                                }
                                this.$store.dispatch('moduleAuth/save', payload)
                                if (this.patentDivisionList.length > 0) {
                                    this.patentDivisionList.forEach(division => {
                                        data = {
                                            id: 0,
                                            patentId: this.patentId,
                                            docNumber: division.docNumber,
                                            countryId: division.countryId,
                                            kind: division.kind,
                                        }

                                        controller = {
                                            name: 'PatentDivision'
                                        }

                                        payload = {
                                            data: data,
                                            controller: controller
                                        }
                                        this.$store.dispatch('moduleAuth/save', payload)
                                    })
                                }
                                if (this.patentInventorList.length > 0) {
                                    this.patentInventorList.forEach(inventor => {
                                        if (inventor.nationalityIDNumber != null) {
                                            this.nationalityIDNumber = inventor.nationalityIDNumber
                                        }
                                        if (inventor.address != null) {
                                            this.address = inventor.address
                                        }
                                        data = {
                                            id: 0,
                                            patentId: this.patentId,
                                            name: inventor.name,
                                            address: this.address,
                                            countryId: inventor.countryId,
                                            nationalityIDNumber: this.nationalityIDNumber
                                        }
                                        const controller = {
                                            name: 'PatentInventor'
                                        }
                                        const payload = {
                                            data: data,
                                            controller: controller
                                        }

                                        this.$store.dispatch('moduleAuth/save', payload)

                                    })
                                }
                                if (this.patentPriorityList.length > 0) {
                                    this.patentPriorityList.forEach(priority => {
                                        data = {
                                            id: 0,
                                            patentId: this.patentId,
                                            countryId: priority.countryId,
                                            date: priority.date,
                                            no: priority.no
                                        }
                                        const controller = {
                                            name: 'PatentPriority'
                                        }
                                        const payload = {
                                            data: data,
                                            controller: controller
                                        }
                                        this.$store.dispatch('moduleAuth/save', payload)
                                    })
                                }
                                this.$SavePatentAlert(this.patentId)
                                this.getPatentList();
                                this.$bvModal.hide('epoXmlFormModal')
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        saveOwnerFromXml() {
            if (this.patentOwnerList.length > 0) {
                let data = {
                    ownerName: this.patentOwnerList[0].name,
                    ownerAddress: this.patentOwnerList[0].address,
                    ownerAddressCountryId: this.patentOwnerList[0].countryId,
                    employeeId: localStorage.getItem('EmployeeId')
                }
                let controller = {
                    name: 'Client',
                    actionName: 'SaveOwnerClientWithAddressFromXml'
                }
                let payload = {
                    data: data,
                    controller: controller
                }
                this.$PrivateConfirm(this.patentOwnerList[0].name, this.$t("alerts.named_owner_from_xml_will_be_saved"), "warning", this.$t("invoice.confirmButtonText"), this.$t("invoice.cancelButtonText")).then(result => {
                    if (result.value) {
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                data = {
                                    clientId: response.data.clientId
                                }
                                controller = {
                                    name: 'Client',
                                    actionName: 'GetOwnerClientByName'
                                }
                                payload = {
                                    data: data,
                                    controller: controller
                                }
                                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                                    this.patentOwnerOption = response.data.clientList
                                    this.patentOwnerSelect = response.data.clientList[0]
                                    
                                })
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        })
                    }
                })
            }
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

.agentInputCSS {
    width: 100% !important;
    max-width: none;
    min-width: 700px;
    box-sizing: border-box;
}

.b-input-group {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
}

.b-input-group-append {
    flex-shrink: 0;
}

.v-select .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}
</style>
