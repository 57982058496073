<template>
<div>
    <!-- modal -->
    <b-modal id="patentFormModal" centered size="xl" no-close-on-backdrop ref="patentFormModal" :title="$t('patent.patent_info')" hide-footer>
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <client-form ref="clientFormModal"></client-form>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <b-card>
            <div class="custom-search d-flex justify-content-end mb-1">
                <b-row>
                    <b-col>
                        <b-button variant="outline-primary" right size="sm" @click.prevent="addOrUpdate">
                            {{ $t('others.ok_title') }}
                        </b-button>
                        <b-dropdown v-if="id > 0" class="ml-05" right style=" height: 2.1em; " size="sm" :text="$t('others.invoices')" variant="outline-primary">
                            <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                            <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown style=" height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                            <template #button-content>
                                <feather-icon icon="SendIcon" size="10" class="align-middle" />
                            </template>
                            <div class="dropdown-scroll">
                                <b-dropdown-item @click="preparePatentMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                    <span>{{mailTemplate.explanation}}</span>
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col">
                        <b-form>
                            <label for="patent-name-input">{{ $t('patent.patent_folder_no') }}</label>
                            <b-form-group>
                                <b-form-input id="patent-name-input" v-model="patentRefCode" size="sm" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="patent-title-input">{{ $t('patent.title') }}</label>
                            <b-form-group>
                                <b-form-input id="patent-title-input" v-model="title" maxlength="250" size="sm" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <b-form>
                            <label for="patent-title-en-input">{{ $t('patent.title_en') }}</label>
                            <b-form-group>
                                <validation-provider #default="{ errors }" :name="$t('patent.title_en')" rules="required">
                                    <b-form-input id="patent-title-en-input" v-model="titleEN" maxlength="250" size="sm" :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <label for="patent-publication-type">{{ $t('patent.publication_type') }}</label>
                        <b-form>
                            <b-form-select class="custom-height" v-model="patentPublicationTypeSelect" value-field="id" text-field="name" :options="patentPublicationTypeOption" />
                        </b-form>
                    </b-col>
                    <!--buraya al-->
                    <b-col class="col">
                        <b-form>
                            <label for="patent-application-no-input">{{ $t('patent.application_no') }}</label>
                            <b-form-group>
                                <b-form-input id="patent-application-no-input" v-model="applicationNo" maxlength="250" size="sm" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col">
                        <label for="patent-last-application-date-input">{{ $t('patent.last_application_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="lastApplicationDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col">
                        <label for="patent-deadline-date-input">{{ $t('patent.deadline_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="deadlineDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col">
                        <label for="patent-instruction-date-input">{{ $t('patent.instruction_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
                    </b-col>
                    <b-col class="col">
                        <label for="patent-type">{{ $t('patent.patent_type') }}</label>
                        <b-form>
                            <b-form-select class="custom-height" v-model="patentTypeSelect" value-field="id" text-field="name" :options="patentTypeOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col">
                        <label for="patent-application-date-input">{{ $t('patent.application_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDate"></CustomDatePicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <label for="patent-agent-ref-code-input">{{ $t('patent.patent_agent_ref_code') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="patent-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row v-if="hiddenControl == false">
                    <b-col class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="patent-agent-input"> {{ $t('patent.agent') }} </label>
                            <b-input-group class="d-flex align-items-center">
                                <b-form-group label-for="patent-agent-input" class="flex-grow-1 mb-0">
                                    <v-select id="patent-agent-input" v-model="patentAgentSelect" :options="patentAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                                </b-form-group>
                                <b-input-group-append>
                                    <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{ this.$t('others.add_client') }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(patentAgentSelect.clientAccountingId)">
                                            <span>{{ this.$t('others.show_note') }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(patentAgentSelect.clientId)">
                                            <span>{{ this.$t('others.show_agent') }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-else-if="hiddenControl == true">
                    <b-col class="col-lg-12 mt-1">
                        <b-form-group>
                            <label for="patent-agent-input"> {{ $t('patent.agent') }} </label>
                            <b-input-group class="d-flex align-items-center">
                                <b-form-group label-for="patent-agent-input" class="flex-grow-1 mb-0">
                                    <v-select id="patent-agent-input" v-model="patentAgentSelect" :options="patentAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                                </b-form-group>
                                <b-input-group-append>
                                    <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{ this.$t('others.add_client') }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(patentAgentSelect.clientId)">
                                            <span>{{ this.$t('others.show_agent') }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="patent-invoice-client-accounting-input">{{ $t('patent.invoice_client_accounting') }}</label>
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="patent-invoice-client-accounting-input" class="flex-grow-1 mb-0">
                                <v-select id="patent-invoice-client-accounting-input" v-model="patentInvoiceClientSelect" :options="patentInvoiceClientOption" @input="setClientIdWithInvoiceClientCheck" label="clientName" @search="onInvoiceClientAccountingSearch" class="w-100"></v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{ this.$t('others.add_client') }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(patentInvoiceClientSelect.clientAccountingId)">
                                        <span>{{ this.$t('others.show_note') }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(patentInvoiceClientSelect.clientId)">
                                        <span>{{ this.$t('others.show_agent') }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <label for="patent-renewal-client-input">{{ $t('patent.renewal_agent') }}</label>
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="patent-renewal-client-input" class="flex-grow-1 mb-0">
                                <v-select id="patent-renewal-client-input" v-model="patentRenewalClientSelect" :options="patentRenewalClientOption" label="clientName" @search="onRenewalClientSearch" class="w-100"></v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{ this.$t('others.add_client') }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(patentRenewalClientSelect.clientAccountingId)">
                                        <span>{{ this.$t('others.show_note') }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(patentRenewalClientSelect.clientId)">
                                        <span>{{ this.$t('others.show_agent') }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <!--EK BİLGİLER -->
                <b-row>
                    <app-collapse class="ml-1 mb-1 mt-2" type="margin" style="width: 100%">
                        <app-collapse-item :title="$t('others.additional_information')">
                            <b-row v-if="this.patentPublicationTypeSelect == 3 || this.patentPublicationTypeSelect == 1">
                                <b-col v-if="this.epApplicationNo == null" class="col-md-3">
                                    <label for="patent-ep-application-no-input">{{ $t('patent.ep_application_no') }}</label>
                                    <b-form-group>
                                        <b-form-input id="patent-ep-application-no-input" v-model="epApplicationNo" maxlength="250" size="sm" />
                                    </b-form-group>
                                </b-col>
                                <b-col v-if="this.epApplicationNo != null" class="col-md-3">
                                    <b-form>
                                        <b-row>
                                            <b-col class="col-lg-10">
                                                <label for="patent-ep-application-no-input">{{ $t('patent.ep_application_no') }}</label>
                                                <b-form-group>
                                                    <b-form-input id="patent-ep-application-no-input" v-model="epApplicationNo" maxlength="250" size="sm" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col class="col-sm-1 d-flex justify-content-end" style="width: 3em; height: 2em; margin-top:1.8em">
                                                <b-button variant="outline-primary" class="btn-icon" size="sm" :href="linkPlusApplicationNo" target="_blank">
                                                    <feather-icon icon="LinkIcon" />
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label>{{ $t('patent.ep_application_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="epApplicationDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-ep-publication-no-input">{{ $t('patent.ep_publication_no') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-ep-publication-no-input" v-model="epPublicationNo" maxlength="250" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-ep-publication-date-input">{{ $t('patent.ep_publication_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="epPublicationDate"></CustomDatePicker>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-owner-reference-no-input">{{ $t('patent.owner_reference_no') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-owner-reference-no-input" v-model="ownerReferenceNo" maxlength="250" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-pct-application-no-input">{{ $t('patent.pct_application_no') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-pct-application-no-input" v-model="pctApplicationNo" maxlength="250" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-pct-application-date-input">{{ $t('patent.pct_application_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="pctApplicationDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-image-count-input">{{ $t('patent.image_count') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-image-count-input" v-model="imageCount" maxlength="250" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-3">
                                    <label for="patent-ep-b1-pub-date-input">{{ $t('patent.ep_b1_pub_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="epB1PubDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-ep-b2-pub-date-input">{{ $t('patent.ep_b2_pub_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="epB2PubDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-ep-b3-pub-date-input">{{ $t('patent.ep_b3_pub_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="epB3PubDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-provisional-application-date-input">{{ $t('patent.provisional_application_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="provisionalApplicationDate"></CustomDatePicker>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-3">
                                    <label for="patent-ep-b9-pub-date-input">{{ $t('patent.ep_b9_pub_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="epB9PubDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-b2-filing-date-input">{{ $t('patent.b2_filling_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="b2FilingDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-b3-filing-date-input">{{ $t('patent.b3_filling_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="b3FilingDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-b9-filing-date-input">{{ $t('patent.b9_filling_date') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="b9FilingDate"></CustomDatePicker>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-3">
                                    <label for="patent-date-of-grant-notification-input">{{ $t('patent.date_of_grant_notification') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="dateOfGrantNotification"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-deadline-for-payment-of-grant-fees-input">{{ $t('patent.deadline_for_payment_of_grant_fees') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="deadlineForPaymentOfGrantFees"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-date-of-payment-of-grant-fees-input">{{ $t('patent.date_of_payment_of_grant_fees') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="dateOfPaymentOfGrantFees"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-date-of-grant-input">{{ $t('patent.date_of_grant') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="dateOfGrant"></CustomDatePicker>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-patent-no-input">{{ $t('patent.patent_no') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-patent-no-input" v-model="patentNo" maxlength="250" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-date-of-working-declaration-input">{{ $t('patent.date_of_working_declaration')}}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="dateOfWorkingDeclaration"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-working-declaration-deadline-input">{{ $t('patent.working_declaration_deadline')}}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="workingDeclarationDeadline"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-tr-designation-input">{{ $t('patent.tr_designation') }}</label>
                                    <br>
                                    <b-form-checkbox class="custom-control-success" name="patent-tr-designation-input" id="patent-tr-designation-input" v-model="trDesignation" switch>
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-ipc-code-input">{{ $t('patent.ipc_code') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-ipc-code-input" v-model="ipcCode" maxlength="250" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-pct-publication-no-input">{{ $t('patent.pct_publication_no') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-pct-publication-no-input" v-model="pctPublicationNo" maxlength="250" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-pct-publication-date-input">{{ $t('patent.pct_publication_date')}}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="pctPublicationDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <label for="patent-priority-document-date-input">{{ $t('patent.priority_document_date')}}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="priorityDocumentDate"></CustomDatePicker>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-md-3">
                                    <label for="patent-priority-document-deadline-date-input">{{ $t('patent.priority_document_deadline_date')}}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="priorityDocumentDeadlineDate"></CustomDatePicker>
                                </b-col>
                                <b-col class="col-md-3">
                                    <b-form>
                                        <label for="patent-ep-patent-no-input">{{ $t('patent.ep_patent_no') }}</label>
                                        <b-form-group>
                                            <b-form-input id="patent-ep-patent-no-input" v-model="epPatentNo" maxlength="20" size="sm" />
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                            </b-row>
                        </app-collapse-item>
                    </app-collapse>
                </b-row>
            </validation-observer>
        </b-card>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title="$t('patent.owner')" style="width:100%;">
                            <b-card-text>
                                <patent-owner-list  :getClientInfo="getClientInfo" :getPatentList="getPatentList" :patentId="this.id"></patent-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.inventor')" style="width:100%;">
                            <b-card-text>
                                <patent-inventor-list :getPatentList="getPatentList" :patentId="this.id"></patent-inventor-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="4"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.patent_history')" style="width:100%;">
                            <b-card-text>
                                <patent-history-list :getPatentList="getPatentList" :patentId="this.id"></patent-history-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.patent_annuity')" style="width:100%;">
                            <b-card-text>
                                <patent-annuity-list :getPatentList="getPatentList" :patentId="this.id"></patent-annuity-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.translate')" style="width:100%;">
                            <b-card-text>
                                <patent-translate-list :getPatentList="getPatentList" :patentId="this.id"></patent-translate-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.responsible')" style="width:100%;">
                            <b-card-text>
                                <patent-responsible-list :patentId="this.id" :clientId="this.clientId"></patent-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.division')" style="width:100%;">
                            <b-card-text>
                                <patent-division-list :getPatentList="getPatentList" :patentId="this.id"></patent-division-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.priority')" style="width:100%;">
                            <b-card-text>
                                <patent-priority-list :getPatentList="getPatentList" :patentId="this.id"></patent-priority-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.patent_note')" style="width:100%;">
                            <b-card-text>
                                <patent-note-list :getPatentList="getPatentList" :patentId="this.id"></patent-note-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('patent.patent_bulletin_review')" style="width:100%;">
                            <b-card-text>
                                <patent-bulletin-review-list :getPatentList="getPatentList" :patentId="this.id"></patent-bulletin-review-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="4" :recordNo="this.patentRefCode"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="4"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        patentForm: Object,
        getPatentList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            patentRefCode: '',
            applicationNo: '',
            applicationDate: null,
            lastApplicationDate: null,
            instructionDate: null,
            deadlineDate: null,
            pctApplicationDate: null,
            epPublicationDate: null,
            epApplicationDate: null,
            pctPublicationDate: null,
            priorityDocumentDate: null,
            priorityDocumentDeadlineDate: null,
            epB1PubDate: null,
            epB2PubDate: null,
            epB3PubDate: null,
            epB9PubDate: null,
            b2FilingDate: null,
            b3FilingDate: null,
            b9FilingDate: null,
            provisionalApplicationDate: null,
            dateOfGrantNotification: null,
            deadlineForPaymentOfGrantFees: null,
            dateOfPaymentOfGrantFees: null,
            dateOfGrant: null,
            dateOfWorkingDeclaration: null,
            workingDeclarationDeadline: null,
            title: '',
            titleEN: '',
            ipcCode: '',
            pctPublicationNo: '',
            epApplicationNo: '',
            epPublicationNo: '',
            ownerReferenceNo: '',
            pctApplicationNo: '',
            imageCount: '',
            patentNo: '',
            trDesignation: false,
            patentAgentSelect: 0,
            patentAgentOption: [],
            patentPublicationTypeSelect: 0,
            patentPublicationTypeOption: [],
            patentTypeSelect: 0,
            patentTypeOption: [],
            agentReferenceNo: '',
            hiddenControl: true,
            patentInvoiceClientSelect: 0,
            patentInvoiceClientOption: [],
            invoiceClientId: 0,
            wordTemplateList: [],
            mailTemplateList: [],
            epPatentNo: '',
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            clientAccountingId: 0,
            patentRenewalClientOption: [],
            patentRenewalClientSelect: 0,
            renewalClientAccountingId: 0,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 4, this.id)
        },
        async openBulletin(bulletinNo, bulletinPage) {
            this.$refs.bulletinInfoModal.showInfo(bulletinNo, bulletinPage, 2)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 4)
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    ClientAccountingTypeId: 1,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.patentAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    ClientAccountingTypeId: 1,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.patentInvoiceClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id) {
            this.$refs['patentFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Patent'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.wordTemplateList = response.data.wordTemplateList
                    this.mailTemplateList = response.data.mailTemplateList
                    this.applicationDate = response.data.patent.applicationDate
                    this.applicationNo = response.data.patent.applicationNo
                    this.lastApplicationDate = response.data.patent.lastApplicationDate
                    this.ownerReferenceNo = response.data.patent.ownerReferenceNo
                    this.pctApplicationDate = response.data.patent.pctApplicationDate
                    this.pctApplicationNo = response.data.patent.pctApplicationNo
                    this.instructionDate = response.data.patent.instructionDate
                    this.deadlineDate = response.data.patent.deadline
                    this.imageCount = response.data.patent.imageCount
                    this.ipcCode = response.data.patent.ipcCode
                    this.pctPublicationDate = response.data.patent.pctPublicationDate
                    this.pctPublicationNo = response.data.patent.pctPublicationNo
                    this.priorityDocumentDate = response.data.patent.priorityDocumentDate
                    this.priorityDocumentDeadlineDate = response.data.patent.priorityDocumentDeadlineDate
                    this.title = response.data.patent.title
                    this.titleEN = response.data.patent.titleEn
                    this.epApplicationDate = response.data.patent.epApplicationDate
                    this.epApplicationNo = response.data.patent.epApplicationNo
                    this.epPublicationDate = response.data.patent.epPublicationDate
                    this.epPublicationNo = response.data.patent.epPublicationNo
                    this.epB1PubDate = response.data.patent.epB1PubDate
                    this.epB2PubDate = response.data.patent.epB2PubDate
                    this.epB3PubDate = response.data.patent.epB3PubDate
                    this.epB9PubDate = response.data.patent.epB9PubDate
                    this.provisionalApplicationDate = response.data.patent.provisionalApplicationDate
                    this.trDesignation = response.data.patent.isTrDesignation
                    this.b2FilingDate = response.data.patent.b2FilingDate
                    this.b3FilingDate = response.data.patent.b3FilingDate
                    this.b9FilingDate = response.data.patent.b9FilingDate
                    this.dateOfGrantNotification = response.data.patent.dateOfGrantNotification
                    this.deadlineForPaymentOfGrantFees = response.data.patent.deadlineForPaymentOfGrantFees
                    this.dateOfPaymentOfGrantFees = response.data.patent.dateOfPaymentOfGrantFees
                    this.patentNo = response.data.patent.patentNo
                    this.dateOfGrant = response.data.patent.dateOfGrant
                    this.dateOfWorkingDeclaration = response.data.patent.dateOfWorkingDeclaration
                    this.workingDeclarationDeadline = response.data.patent.workingDeclarationDeadline
                    this.patentRefCode = response.data.patent.recordNo
                    this.agentReferenceNo = response.data.patent.agentReferenceNo
                    this.patentPublicationTypeOption = response.data.patentPublicationTypeList
                    this.patentTypeOption = response.data.patentTypeList
                    this.patentPublicationTypeSelect = response.data.patent.patentPublicationTypeId
                    this.patentTypeSelect = response.data.patent.patentTypeId
                    this.epPatentNo = response.data.patent.epPatentNo
                    this.patentTypeOption.splice(0, 0, this.$nullSelected())
                    this.patentPublicationTypeOption.splice(0, 0, this.$nullSelected())
                    var link = response.data.patent.epApplicationNo
                    if (link != null) {
                        var linkSplice = link.indexOf('.')
                        link = link.substring(0, linkSplice);
                        this.linkPlusApplicationNo = 'https://register.epo.org/application?number=' + 'EP' + link
                    }
                    if (response.data.patent.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.patent.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.patentAgentOption = clientAccountingResponse.data.clientList
                            this.patentAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.patentAgentOption = []
                        this.patentAgentSelect = 0;
                    }
                    if (response.data.patent.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.patent.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.patentInvoiceClientOption = clientAccountingResponse.data.clientList
                            this.patentInvoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.patentInvoiceClientOption = []
                        this.patentInvoiceClientSelect = 0;
                    }
                    //STOCK-1491
                    if (response.data.patent.renewalClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.patent.renewalClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.patentRenewalClientOption = clientAccountingResponse.data.clientList
                            this.patentRenewalClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.patentRenewalClientOption = []
                        this.patentRenewalClientSelect = 0;
                    }
                    //
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.patent.clientAccountingId > 0)
                            accountingId = response.data.patent.clientAccountingId
                        if (response.data.patent.invoiceClientAccountingId > 0)
                            accountingId = response.data.patent.invoiceClientAccountingId
                        this.clientAccountingId = accountingId
                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 4,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('patentFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadPatentWordFile(documentId, name) {
            const data = {
                moduleId: 4,
                documentId: documentId,
                patentId: this.id
            }
            const controller = {
                name: 'Patent',
                actionName: 'DownloadPatentWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.patentInvoiceClientSelect === null) {
                this.invoiceClientId = 0
            } else {
                this.invoiceClientId = this.patentInvoiceClientSelect.clientAccountingId
            }
            if (this.patentRenewalClientSelect === null) {
                this.renewalClientAccountingId = 0
            } else {
                this.renewalClientAccountingId = this.patentRenewalClientSelect.clientAccountingId;

            }
            const data = {
                id: this.id,
                agentReferenceNo: this.agentReferenceNo,
                clientAccountingId: this.patentAgentSelect.clientAccountingId,
                applicationDate: this.applicationDate,
                applicationNo: this.applicationNo,
                lastApplicationDate: this.lastApplicationDate,
                ownerReferenceNo: this.ownerReferenceNo,
                pctApplicationDate: this.pctApplicationDate,
                pctApplicationNo: this.pctApplicationNo,
                instructionDate: this.instructionDate,
                deadline: this.deadlineDate,
                imageCount: this.imageCount,
                ipcCode: this.ipcCode,
                pctPublicationDate: this.pctPublicationDate,
                pctPublicationNo: this.pctPublicationNo,
                priorityDocumentDate: this.priorityDocumentDate,
                priorityDocumentDeadlineDate: this.priorityDocumentDeadlineDate,
                title: this.title,
                titleEn: this.titleEN,
                epApplicationDate: this.epApplicationDate,
                epApplicationNo: this.epApplicationNo,
                epPublicationDate: this.epPublicationDate,
                epPublicationNo: this.epPublicationNo,
                epB1PubDate: this.epB1PubDate,
                epB2PubDate: this.epB2PubDate,
                epB3PubDate: this.epB3PubDate,
                epB9PubDate: this.epB9PubDate,
                provisionalApplicationDate: this.provisionalApplicationDate,
                isTrDesignation: this.trDesignation,
                b2FilingDate: this.b2FilingDate,
                b3FilingDate: this.b3FilingDate,
                b9FilingDate: this.b9FilingDate,
                dateOfGrantNotification: this.dateOfGrantNotification,
                deadlineForPaymentOfGrantFees: this.deadlineForPaymentOfGrantFees,
                dateOfPaymentOfGrantFees: this.dateOfPaymentOfGrantFees,
                patentNo: this.patentNo,
                dateOfGrant: this.dateOfGrant,
                dateOfWorkingDeclaration: this.dateOfWorkingDeclaration,
                workingDeclarationDeadline: this.workingDeclarationDeadline,
                patentPublicationTypeId: this.patentPublicationTypeSelect,
                patentTypeId: this.patentTypeSelect,
                invoiceClientAccountingId: this.invoiceClientId,
                epPatentNo: this.epPatentNo,
                renewalClientAccountingId: this.renewalClientAccountingId
            }

            const controller = {
                name: 'Patent'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getPatentList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.patentId
                                this.patentRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                            // this.correctApplicationNo = applicationNo
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        preparePatentMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                patentId: this.id
            }
            const controller = {
                name: 'Patent',
                actionName: 'PreparePatentMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        setClientIdWithInvoiceClientCheck(event) {
            if (event != null) {
                if (this.patentInvoiceClientSelect.forInvoice == false) {
                    this.clientId = null;
                    this.patentInvoiceClientSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
        onRenewalClientSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.renewalClientSearch(loading, search, this);
            }
        },
        renewalClientSearch(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    ClientAccountingTypeId: 1,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.patentRenewalClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

[dir] .custom-height {
    height: 30px;
}

.b-tab {
    max-width: 70em;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
