<template>
<div>
    <tpmk-excel-save-form :getAnnuityList="getAnnuityList" ref="tpmkExcelSaveFormModal"></tpmk-excel-save-form>
    <!-- modal -->
    <b-modal id="annuityFormModal" centered size="xl" no-close-on-backdrop ref="annuityFormModal" :title=" $t('annuity.annuity_detail_info')" hide-footer>
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-12">
                    <div style="float:right;" class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <b-button v-if="this.id != 0" style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click.prevent="showTpmkExelForm">
                                    {{$t('annuity.upload_tpmk_receipt')}}
                                </b-button>
                                <b-button v-if="this.id != 0" style="float:right;" class="ml-1" size="sm" variant="outline-primary" @click.prevent="createHenryExcel">
                                    {{$t('annuity.henry_excel')}}
                                </b-button>
                                <b-button style="float:right;" variant="outline-primary" size="sm" class="ml-1" @click.prevent="addOrUpdate">
                                    {{$t('others.ok_title')}}
                                </b-button>
                            </div>
                        </b-form-group>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-5">
                    <b-form>
                        <label for="agent-annuity-lis-name-input"> {{ $t('annuity.lis_agent_name') }} </label>
                        <b-form-group>
                            <validation-provider :name=" $t('annuity.lis_agent_name')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-select :state="errors.length > 0 ? false:null" v-model="lisAgentSelect" value-field="id" text-field="explanation" size="sm" :options="lisAgentOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-5">
                    <validation-provider #default="{ errors }" :name=" $t('annuity.folder_name')" rules="required">
                        <label for="agent-annuity-folder-name-input">{{ $t('annuity.folder_name') }}</label>
                        <b-input-group>
                            <b-form-input size="sm" id="folder-name-input" v-model="documentName" maxlength="50" :state="errors.length > 0 ? false:null" />
                            <b-input-group-append>
                                <b-button size="sm" variant="outline-primary" onclick="document.getElementById('annuityFileSelect').click()">{{ $t('others.select') }}
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col v-if="this.id != 0" class="col-md-2">
                    <b-form>
                        <label for="agent-annuity-lis-name-input"> {{ $t('annuity.status') }} </label>
                        <b-form-group>
                            <b-form-select v-model="statusSelect" size="sm" value-field="id" text-field="name" :options="statusOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-form-file size="sm" style="display:none" id="annuityFileSelect" accept="*.*" @change="uploadFile" :placeholder=" $t('annuity.select_folder')" :drop-placeholder=" $t('annuity.select_folder_info')" />
            </b-row>
        </validation-observer>
        <b-card :hidden="hiddenControl">
            <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                <b-tab :title=" $t('annuity.annuity_detail')" style="width:100%;">
                    <b-card-text>
                        <annuity-detail-list :getAnnuityList="getAnnuityList" :annuityId="this.id"></annuity-detail-list>
                    </b-card-text>
                </b-tab>
                <b-tab :title=" $t('others.digital_archive')" style="width:100%;">
                    <b-card-text>
                        <documents-list :recordId="this.id" :moduleId="18"></documents-list>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getAnnuityList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            lisAgentSelect: 0,
            lisAgentOption: [],
            // folderName: '',
            // mismatchedRecords: 0,
            // noReceipt: 0,
            statusSelect: 0,
            statusOption: [],
            hiddenControl: true,
            agentSelect: 0,
            agentOption: [],
            agentId: 0,
            documentName: '',
            documentFile: '',
            documentType: '',
            lisAgentManuelList: [],
        }
    },
    methods: {
        async uploadFile(e) {
            var fileInfo = await this.$getFileInfo(e.target.files[0])
            this.documentName = fileInfo.name
            this.documentType = fileInfo.contentType
            this.documentFile = fileInfo.base64
        },
        showInfo(id) {
            this.$refs['annuityFormModal'].show()
            this.rows = []
            this.id = id
            if (this.id == 0) {
                this.hiddenControl = true
            } else {
                this.hiddenControl = false
            }
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Annuity'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.lisAgentOption = response.data.agentAnnuityList
                    this.lisAgentSelect = response.data.annuity.agentAnnuityId
                    this.statusSelect = response.data.annuity.annuityStatusId
                    this.statusOption = response.data.annuityStatusList
                    this.documentName = response.data.annuity.fileName
                    this.lisAgentOption.splice(0, 0, this.$nullSelected('explanation'))
                } else {
                    this.$bvModal.hide('annuityFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        showTpmkExelForm() {
            this.$refs.tpmkExcelSaveFormModal.showInfo(this.id)
        },
        createHenryExcel() {
            const data = {
                id: this.id,
            }
            const controller = {
                name: 'AnnuityDetail',
                actionName: 'DownloadHenryExcelByAnnuityId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Henry Excel", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                agentAnnuityId: this.lisAgentSelect,
                currencyId: this.currencySelect,
                annuityStatusId: this.statusSelect,
                fileName: this.documentName,
                base64: this.documentFile
            }
            const controller = {
                name: 'Annuity',
                actionName: 'SaveAnnuity'
            }
            const payload = {
                data: data,
                controller: controller
            }

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    let loader = this.$loading.show()
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('annuityFormModal')
                            this.getAnnuityList()
                            this.$SaveAlert()
                            this.hiddenClientControl = false
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                        loader.hide()
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
