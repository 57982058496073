import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  install(Vue) {

    Vue.prototype.$formatFn = function (event) {
      if (event == null) {
        return "-";
      } else if (event != null) {
        var options = { day: "numeric", month: "numeric", year: "numeric" };
        const dateFormatted = new Date(event);
        return dateFormatted.toLocaleDateString("tr-TR", options);
      }
    };

    Vue.prototype.$formatMoney = function (value) {
      if(value == null)  {
        return 0;
      }
      else{
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
    };

    Vue.prototype.$decimalValFunc = function (val) {
      if(val != 0 && val != null){
        var decimalVal = val.replace(/,\s*/g, '.');
        return decimalVal
      } else return 0
    }

    Vue.prototype.$nullSelected = function (fieldName = "name") {
      var data = {};
      data["id"] = 0;
      data[fieldName] = this.$t("others.null_selected");
      return data;
    };

    Vue.prototype.$nullSelectedByNames = function (keyName, fieldName) {
      var data = {};
      data[keyName] = 0;
      data[fieldName] = this.$t("others.null_selected");
      return data;
    };


    Vue.prototype.$EmptyDateToNull = function (value) {
      if(value == "")
        value = null
      return value;
    }

    Vue.prototype.$CalculateTheDayCount = function (startDate, endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      const time = startDate - endDate      
      var daysDifference = time / (1000 * 60 * 60 * 24)  
      return daysDifference;
    }

    Vue.prototype.$DeleteConfirm = function () {
      return this.$CustomConfirm(
        this.$t("alerts.delete_confirm"),
        this.$t("alerts.delete_confirm_question"),
        "warning"
      );
    };
    Vue.prototype.$DeleteInvoiceConfirm = function (count) {
      return this.$CustomHtmlConfirm(
        this.$t("alerts.delete_confirm"),
        count +' '+ this.$t("alerts.delete_invoice_confirm_question") + '<br>' + this.$t("alerts.delete_invoice_confirm_questionNote"),
        "warning"
      );
    };

    Vue.prototype.$DeleteClientConfirm = function (
      title,
      message,
      confirmButtonText
    ) {
      return this.$swal({
        title: title,
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: this.$t("alerts.not_delete_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });
    };

    Vue.prototype.$CustomConfirm = function (title, message, icon) {
      return this.$swal({
        title: title,
        text: message,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: this.$t("alerts.delete_button"),
        cancelButtonText: this.$t("alerts.not_delete_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });
    };

    Vue.prototype.$CustomHtmlConfirm = function (title, message, icon) {
      return this.$swal({
        title: title,
        html: message,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: this.$t("alerts.delete_button"),
        cancelButtonText: this.$t("alerts.not_delete_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });
    };
        
    Vue.prototype.$PrivateConfirm = function (title, message, icon,confirmButtonText,cancelButtonText) {
      return this.$swal({
        title: title,
        text: message,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });
    };
    
    Vue.prototype.$getFileInfo = function (file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          const fileInfo = {
            name: file.name.split(".").slice(0, -1).join("."),
            contentType: file.name.split(".").pop(),
            base64: reader.result.split("base64,").pop(),
          };
          resolve(fileInfo);
        };
        reader.readAsDataURL(file);
      });
    };
    Vue.prototype.$getMultipleFilesInfo = function (files) {
      const filePromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
    
          reader.onload = () => {
            const fileInfo = {};
            fileInfo.name = file.name.split(".")[0];
            fileInfo.contentType = file.name.split(".")[1];
            fileInfo.base64 = reader.result.split("base64,").pop();
            resolve(fileInfo);
          };
    
          reader.readAsDataURL(file);
        });
      });
    
      return Promise.all(filePromises);
    };

    Vue.prototype.$downloadFile = function (
      title,
      base64Data,
      contentType,
      sliceSize = 512
    ) {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const url = window.URL.createObjectURL(
        new Blob(byteArrays, { type: contentType })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title + "." + contentType);
      document.body.appendChild(link);
      link.click();
    };
    
    Vue.prototype.$SaveAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.save"), "success");
    };

    Vue.prototype.$SavePatentAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.patent_id_save"), "success");
    };

    Vue.prototype.$ErrorSaveAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.table_is_empty"), "danger");
    };

    Vue.prototype.$TranslatePaymentCantUpdate = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.translate_payment_cant_update"), "danger");
    };

    Vue.prototype.$PleaseClickSave = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.please_click_save"), "primary");
    };

    Vue.prototype.$ChangePasswordInfoAlert = function () {
      Vue.prototype.$CustomAlert(
        this.$t("alerts.change_password_after"),
        "info"
      );
    };
    Vue.prototype.$UpdateAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.update"), "success");
    };
    Vue.prototype.$AuthorazationAlert = function () {
      Vue.prototype.$CustomAlert(
        this.$t("alerts.authorization_alert"),
        "warning"
      );
    };
    Vue.prototype.$IsPaid = function () {
      Vue.prototype.$CustomAlert(this.$t("others.is_paid"), "danger");
    };
    Vue.prototype.$DeleteAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.delete"), "success");
    };
    Vue.prototype.$CreateCertificateAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("others.created_certificate"), "success");
    };
    Vue.prototype.$InvoiceDetailError = function () {
      Vue.prototype.$CustomAlert(this.$t("others.invoice_detail_error"), "danger");
    };
    Vue.prototype.$CantDeletePaidPayment = function () {
      Vue.prototype.$CustomAlert(this.$t("others.cant_delete_paid_payment"), "danger");
    };
    Vue.prototype.$WarningAlert = function (title) {
      Vue.prototype.$CustomAlert(title, "warning");
    };
    Vue.prototype.$CopiedAlert = function (title) {
      Vue.prototype.$CustomAlert(this.$t("alerts.copied"), "success");
    };
    Vue.prototype.$NotCopiedAlert = function (title) {
      Vue.prototype.$CustomAlert(this.$t("alerts.not_copied"), "danger");
    };
    Vue.prototype.$CustomWarningAlert = function (title) {
      Vue.prototype.$CustomAlert(this.$t("alerts."+ title), "danger");
    };
    Vue.prototype.$NullRecord = function (title) {
      Vue.prototype.$CustomAlert(this.$t("alerts.record_is_null"), "danger");
    };
    Vue.prototype.$pdfNotExist = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.pdf_not_exist"), "danger");
    };
    Vue.prototype.$ThereIsInvoiceTemplateDetailForThisRecord = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.there_is_invoice_template_detail_for_this_record"), "danger");
    };
    Vue.prototype.$IdIsNotContinueToNextJob = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.id_is_not_continue_to_next_job"), "danger");
    };
    Vue.prototype.$HolidayUpdated = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.holiday_updated"), "success");
    };
    Vue.prototype.$SendForgotPasswordAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.send_form"), "success");
    };
    Vue.prototype.$ForInvoiceAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.for_invoice_is_not_true"), "danger");
    };
    Vue.prototype.$LoginError = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.login_error"), "danger");
    };
    Vue.prototype.$WarningValidateInput = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.fill_in_the_validate_input"), "danger");
    };

    Vue.prototype.$isOwnerOrAgent = function () {
      Vue.prototype.$CustomAlert(
        this.$t("alerts.is_owner_or_alerts"),
        "danger"
      );
    };

    Vue.prototype.$NotRecordAlert = function () {
      Vue.prototype.$CustomAlert(this.$t("alerts.not_record_alert"), "danger");
    };
    Vue.prototype.$MailSendAlert = function () {
      Vue.prototype.$CustomAlert(
        this.$t("alerts.mail_send_successfull"),
        "success"
      );
    };
    Vue.prototype.$ChangePasswordAlert = function () {
      Vue.prototype.$CustomAlert(
        this.$t("alerts.password_change_successfull"),
        "success"
      );
    };
    Vue.prototype.$ThereIsDesignDetailWithNoAlert = function () {
      Vue.prototype.$CustomAlert(
        this.$t("alerts.there_is_design_detail_with_no"),
        "warning"
      );
    };
    Vue.prototype.$AddClientAddressFirst = function () {
      Vue.prototype.$CustomAlert(
        this.$t("alerts.add_client_address_first"),
        "warning"
      );
    };

    Vue.prototype.$CustomAlert = function (title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "CheckIcon",
          variant: icon,
        },
      });
    };
    Vue.prototype.$ClientConflictControl = function (message, icon) {
      return this.$swal({
        title: this.$t("alerts.client_conflict_check"),
        text: message + this.$t("alerts.client_conflict_message"),
        icon: icon,
        showCancelButton: true,
        confirmButtonText: this.$t("alerts.confirm"),
        cancelButtonText: this.$t("alerts.not_delete_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });
    };

    Vue.prototype.$SuccessControl = function (message, icon) {
      return this.$swal({
        title: "Tebrikler!!!",
        text: message,
        icon: icon,
        showCancelButton: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });
    };

    Vue.prototype.$truncateText = function (text, maxLength) {
      if (!text) {
        return '';
      }

      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };
  },
};
