<template>
<div>
    <!-- modal -->
     
    <b-modal id="searchTrademarkForCopyFormModal" ref="searchTrademarkForCopyFormModal" centered size="lg" 
    :title=" $t('searchTrademarkForCopy.info')" :cancel-title=" $t('others.cancel_title')" 
    :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="trademark-record-no-input">{{ $t('searchTrademarkForCopy.trademark_record_no') }}</label>
                    <b-form-group>
                            <v-select id="trademark-record-no-input" v-model="trademarkSelect" :options="trademarkOption" @input="setTrademarkId" label="recordNo" @search="onSearch" class="w-100"></v-select>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getTrademarkList: {
            type: Function
        },
    },
    data() {
        return {
            trademarkId: 0,
            name: '',
            trademarkOption: [],
            trademarkSelect: 0,
            type: '',
        }
    },
    methods: {
        clear() {
            this.trademarkId= 0,
            this.trademarkSelect = 0
            this.trademarkOption = []
        },
        showInfo() {
            this.$refs['searchTrademarkForCopyFormModal'].show()

        },
        async addOrUpdate() {
            //i wrote promise sentence because we'll waiting for emit process 20.11.2024 Furkan (This Code copied from Dundar Law Forms. )
            if (this.trademarkId == 0) {                
                await this.$WarningAlert(this.$t('alerts.record_is_null'))
            }
            else{
                const data = {
                    id: this.trademarkId
                }
                const controller = {
                    name: 'Trademark',
                    actionName: 'GetCopyTrademarkById'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkOption = response.data.trademarkList
                })
                
                await this.getTrademarkList()
                this.$bvModal.hide('searchTrademarkForCopyFormModal')
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    recordNo: search
                }
                const controller = {
                    name: 'Trademark',
                    actionName: 'GetTrademarkListWithRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkOption = response.data.trademarkList
                    loading(false)
                })
            }
        },
        setTrademarkId(event) {
            this.trademarkId = event.id
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.b-modal {
    z-index: 1050 !important;
}
</style>
