<template>
<div>
    <agent-note-form ref="agentNoteFormModal"></agent-note-form>
    <b-modal id="trademarkWipoXmlFormModal" centered size="xl" no-close-on-backdrop ref="trademarkWipoXmlFormModal" :title="$t('wipoXml.wipo_xml_info')" :cancel-title="$t('others.cancel_title')" cancel-variant="outline-secondary" :ok-title="$t('others.ok_title')" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
        <!--Trademark-->
        <b-card :title="$t('wipoXmlTrademark.trademark_info')">
            <b-row>
                <b-col class="col-md-12 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.trademark_name') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <b-card-text>
                                    {{ this.trademarkName }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.application_no') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <validation-observer ref="simpleRules">
                                    <validation-provider #default="{ errors }" :name=" $t('wipoXmlTrademark.application_no')" rules="required">
                                        <b-form-input id="wipo-xml-application-no-input" size="sm" v-model="applicationNo" :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </validation-observer>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col>
                <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.application_date') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <b-card-text>
                                    {{ $formatFn(this.applicationDate) }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col>
            </b-row>
            <b-row>
                <!-- <b-col class="col-md-4 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.notification_date') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <b-card-text>
                                    {{ $formatFn(this.notificationDate) }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col> -->
                <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.register_no') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <b-card-text>
                                    {{ this.registerNo }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col>
                <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.is_transformation') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <b-form-checkbox :checked="isTransformation" class="custom-control-success" name="check-button" v-model="isTransformation" switch>
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.xml_trademark_agent') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <b-card-text>
                                    {{ this.xmlTrademarkAgent }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col>
                <b-col class="col-md-6 mb-1">
                    <b-list-group-item class="flex-column align-items-start">
                        <b-row>
                            <b-col>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6>
                                        {{ $t('wipoXmlTrademark.xml_trademark_owner') }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col>
                                <b-card-text>
                                    {{ this.xmlTrademarkOwner }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <b-form-group>
                        <label for="trademark-agent-input"> {{ $t('trademark.agent') }} </label>
                        <b-input-group>
                            <validation-provider :name=" $t('trademark.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group label-for="trademark-agent-input" :state="errors.length > 0 ? false:null">
                                    <v-select class="agentInput" id="trademark-agent-input" v-model="trademarkAgentSelect" :options="trademarkAgentOption" @input="setClientId" label="clientName" @search="onSearchAgent">
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <b-input-group-append>
                                <b-dropdown style="width: 4em; height: 2em; margin-bottom: 1em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{this.$t('others.add_client')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(trademarkAgentSelect.clientAccountingId)">
                                        <span>{{this.$t('others.show_note')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(trademarkAgentSelect.clientId)">
                                        <span>{{this.$t('others.show_agent')}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12">
                    <b-form>
                        <validation-provider :name="$t('trademarkOwner.owner_name')" #default="{ errors }" rules="required">
                            <b-form-group :label="$t('trademarkOwner.owner_name')" label-for="trademarkOwner" :state="errors.length > 0 ? false : null">
                                <v-select id="trademarkOwner" v-model="trademarkOwnerSelect" :options="trademarkOwnerOption" label="name" @search="onSearchOwner">
                                </v-select>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
        </b-card>
        <!--Trademark Nice Class-->
        <b-card :title="$t('wipoXmlTrademark.nice_class_list')">
            <b-col class="col-md-12 mb-1">
                <vue-good-table id="niceClassTable" ref="niceClassTable" :columns="columnsNiceClass" :rows="this.trademarkNiceClassList" styleClass="vgt-table condensed tableSize" :search-options="{
                    enabled: false,
                  }" :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }" :pagination-options="{
                  enabled: false,
                  }" theme="my-theme">
                    <div slot="emptystate">
                        {{ $t('dataTable.data_not_found') }}
                    </div>
                    <template slot="table-column" slot-scope="props">
                        <span v-if="props.column.field === 'niceClass'" class="text-nowrap">
                            {{ $t('wipoXmlTrademark.nice_class') }}
                        </span>
                        <span v-else-if="props.column.field === 'goodsEn'" class="text-nowrap">
                            {{ $t('wipoXmlTrademark.goods_en') }}
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-card>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getTrademarkList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            columnsNiceClass: [{
                    label: this.$t('wipoXmlTrademark.nice_class'),
                    field: 'niceClass',
                },
                {
                    label: this.$t('wipoXmlTrademark.goods_en'),
                    field: `goodsEn`,
                },
            ],
            applicationNo: '',
            applicationDate: '',
            trademarkName: '',
            notificationDate: '',
            trademarkNiceClassList: [],
            xmlTrademarkAgent: '',
            xmlTrademarkOwner: '',
            trademarkAgentOption: [],
            trademarkAgentSelect: 0,
            trademarkOwnerOption: [],
            trademarkOwnerSelect: 0,
            registerNo: '',
            isTransformation: false
        }
    },
    methods: {
        showInfo(xmlData) {
            this.$refs['trademarkWipoXmlFormModal'].show()
            this.trademarkAgentSelect = 0,
            this.trademarkAgentOption = [],
            this.trademarkOwnerSelect = 0,
            this.trademarkOwnerOption = [],
            this.trademarkNiceClassList = [],
            this.trademarkNiceClassList = xmlData.trademarkNiceClassList
            this.applicationNo = xmlData.xmlTrademarkDto.applicationNo
            this.applicationDate = xmlData.xmlTrademarkDto.applicationDate
            this.trademarkName = xmlData.xmlTrademarkDto.name
            this.notificationDate = xmlData.xmlTrademarkDto.notificationDate
            this.xmlTrademarkAgent = xmlData.xmlTrademarkAgent.name
            this.xmlTrademarkOwner = xmlData.xmlTrademarkOwner.name
            this.registerNo = xmlData.xmlTrademarkDto.registerNo
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearchAgent(search, loading) {
            if (search.length) {
                loading(true);
                this.searchAgent(loading, search, this);
            }
        },
        searchAgent(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    ClientAccountingTypeId: 1,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        onSearchOwner(search, loading) {
            if (search.length) {
                loading(true);
                this.searchOwner(loading, search, this);
            }
        },
        searchOwner(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    name: search
                }
                const controller = {
                    name: 'Client',
                    actionName: 'GetOwnerClientByName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkOwnerOption = response.data.clientList
                    loading(false)
                })
            }
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                let data = {
                    id: 0,
                    name: this.trademarkName,
                    applicationDate: this.applicationDate,
                    applicationNo: this.applicationNo,
                    clientAccountingId: this.trademarkAgentSelect.clientAccountingId,
                    isIr: true,
                    registerNo: this.registerNo,
                    isTransformation: this.isTransformation
                }
                let controller = {
                    name: 'Trademark'
                }
                let payload = {
                    data: data,
                    controller: controller
                }

                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.trademarkId = response.data.trademarkId;
                            if (this.trademarkId > 0) {
                                data = {
                                    id: 0,
                                    trademarkId: this.trademarkId,
                                    clientId: this.trademarkOwnerSelect.id,
                                }
                                controller = {
                                    name: 'TrademarkOwner'
                                }
                                payload = {
                                    data: data,
                                    controller: controller
                                }
                                this.$store.dispatch('moduleAuth/save', payload)
                                if (this.trademarkNiceClassList.length > 0) {

                                    this.trademarkNiceClassList.forEach(niceClassItem => {
                                        data = {
                                            id: 0,
                                            trademarkId: this.trademarkId,
                                            goodsEn: niceClassItem.goodsEn,
                                            niceClass: niceClassItem.niceClass
                                        }
                                        const controller = {
                                            name: 'TrademarkNiceClass'
                                        }
                                        const payload = {
                                            data: data,
                                            controller: controller
                                        }
                                        this.$store.dispatch('moduleAuth/save', payload)
                                    })
                                }
                                this.$SaveAlert()
                                this.getTrademarkList();
                                this.$bvModal.hide('trademarkWipoXmlFormModal')
                                this.$bvModal.hide('trademarkWipoXmlUploadFormModal')
                            }
                        } else {
                            if (response.data.resultMessage == "ThereIsATrademarkRecordWithSameApplicationNo") {
                                this.$CustomAlert(this.$t("alerts.ThereIsATrademarkRecordWithSameApplicationNo"))
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                        }
                    })
                }
            })
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
