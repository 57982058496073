<template>
<div>
    <!-- modal -->
    <b-modal id="businessPartnerContactInformationFormModal" centered size="lg" no-close-on-backdrop ref="businessPartnerContactInformationFormModal" :title=" $t('businessPartnerContactInformation.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-6 mt-1">
                    <b-form>
                        <label for="type-option">{{ $t('businessPartnerContactInformation.contact_type') }}</label>
                        <validation-provider :name=" $t('businessPartnerContactInformation.contact_type')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-select size="sm" v-model="contactTypeSelect" value-field="id" text-field="name" :options="contactTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-6 mt-1">
                    <b-form>
                        <label for="businessPartnerContactInformation-information-input">{{ $t('businessPartnerContactInformation.information') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('businessPartnerContactInformation.name')" rules="required">
                                <b-form-input size="sm" id="businessPartnerContactInformation-Name-input" v-model="information" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getBusinessPartnerContactInformationList: {
            type: Function
        },
        getBusinessPartnerList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            businessPartnerId: 0,
            information: '',
            contactTypeOption: [],
            contactTypeSelect: 0
        }
    },
    methods: {
        showInfo(id, businessPartnerId) {
            this.$refs['businessPartnerContactInformationFormModal'].show()
            this.id = id
            this.businessPartnerId = businessPartnerId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'BusinessPartnerContactInformation'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                this.information = response.data.businessPartnerContactInformation.information
                this.contactTypeOption = response.data.contactTypeList
                if (response.data.businessPartnerContactInformation.contactTypeId > 0) {
                    this.contactTypeSelect = response.data.businessPartnerContactInformation.contactTypeId

                } else {
                    this.contactTypeSelect = 0
                }
                this.contactTypeOption.splice(0, 0, this.$nullSelected())

            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                businessPartnerId: this.businessPartnerId,
                information: this.information,
                contactTypeId: this.contactTypeSelect
            }
            const controller = {
                name: 'BusinessPartnerContactInformation'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('businessPartnerContactInformationFormModal')
                            this.getBusinessPartnerContactInformationList()
                            this.getDesignOppositionList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        clear() {
            this.information = '',
                this.contactTypeSelect = 0
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
